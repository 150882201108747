import { gql } from "@apollo/client";

const CREATE_EXCHANGE_RATE = gql`
  mutation createExchangeRate($input: CreateExchangeRateInput!) {
    createExchangeRate(input: $input) {
      success
      error
    }
  }
`;

export { CREATE_EXCHANGE_RATE };
