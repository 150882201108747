/* eslint-disable max-len */
import { useQuery } from "@apollo/client";

import { Button, getInput, Modal } from "../../../../../../components/ui";
import { FETCH_ENTITIES } from "../../../../../../graphql/query/Entity";
import { FETCH_CONTACT_TYPES } from "../../../../../../graphql/query/ContactType";
import { TrashIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { IconButton } from "../../../../../../components/ui/IconButton";
import { FETCH_COUNTRIES } from "../../../../../../graphql/query/Country";
import useUser from "../../../../../../hooks/useUser";

import ContactAddress from "./components/ContactAddress";
import Methods from "./components/Methods";
import DeleteMailingListModal from "./components/DeleteMailingComponent";
import Header from "./components/Header";
import MailingComponent from "./components/MailingComponent";
import SalesUserComponent from "./components/SalesUserComponent";

const Infos = ({ disabled, formik, submitOnBlur = false }) => {
  const inputs = [
    {
      label: "Id #",
      name: "id",
      type: "text",
      disabled: true,
      submitOnBlur,
    },
    {
      label: "Company",
      name: "company",
      placeholder: "Type to add text",
      type: "text",
      submitOnBlur,
    },
    {
      label: "Job Title",
      name: "title",
      placeholder: "Type to add text",
      type: "text",
      submitOnBlur,
    },
    {
      label: "Important Info",
      name: "prefered",
      placeholder: "Type to edit text",
      type: "textarea",
      rows: 2,
      autoHeight: true,
      submitOnBlur,
    },
  ];

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      disabled,
      formik,
      ...item,
    };

    return (
      <div key={index} className="w-full">
        {getInput(inputProps)}
      </div>
    );
  });

  return <div className="mt-10 grid w-full gap-4">{renderInputs}</div>;
};

const MailingLists = ({ contact }) => {
  const { contactMailingLists } = contact || {};
  const { hasPermission } = useUser();

  const addMailingModalProps = () => ({
    scale: "md",
    hideCloseButton: true,
    closeOnBackdrop: true,
    body: MailingComponent,
    userName: contact?.name,
    contactId: contact?.id,
    mailinList: contactMailingLists?.map(item => item?.mailingList?.id) || [],
  });
  const deleteMailingListProps = contactMailingList => ({
    scale: "sm",
    hideCloseButton: true,
    closeOnBackdrop: true,
    body: DeleteMailingListModal,
    contactMailingList,
  });

  return (
    <div className="mt-10 w-full">
      <div className="mb-6 flex flex-row justify-between">
        <div className="flex flex-row items-center">
          <h1 className="mr-4 mt-1 text-xl font-bold">Mailing Lists</h1>
        </div>
        <div>
          {hasPermission("EDIT_CONTACT_MAILING_LIST") && (
            <Modal {...addMailingModalProps()}>
              <Button
                label="Add To Mailing List"
                action="default"
                className="ml-2"
              />
            </Modal>
          )}
        </div>
      </div>
      {contactMailingLists?.map((item, key) => (
        <div key={key} className="component-table-row w-full">
          <div className="flex flex-row border-b border-black py-2">
            <div className="flex flex-1 flex-col">
              {item?.mailingList?.description}
            </div>
            <div className="flex flex-col">
              <div className="list-actions flex flex-row flex-nowrap">
                {hasPermission("EDIT_CONTACT_MAILING_LIST") && (
                  <Modal {...deleteMailingListProps(item)}>
                    <IconButton variant="clean" title="Delete Method">
                      <TrashIcon
                        className={`h-4 w-4 cursor-pointer text-gray-400`}
                      />
                    </IconButton>
                  </Modal>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const WhiteCubeInternal = props => {
  const {
    disabled,
    formik,
    loading,
    submitOnBlur = false,
    updateContact,
  } = props || {};
  const { data: { contactTypes = [] } = {} } = useQuery(FETCH_CONTACT_TYPES);
  const { data: entitiesData = {} } = useQuery(FETCH_ENTITIES);
  const entities = entitiesData?.entities?.edges || [];
  const salesUser = formik.values.salesUser;
  const salesUser2 = formik.values.salesUser2;
  const { hasPermission } = useUser();
  const edit = hasPermission("CAN_EDIT_SALES_CONTACT");

  const inputs = [
    {
      label: "Type",
      name: "typeId",
      type: "multi-select",
      options: [
        { label: "All", value: null },
        ...contactTypes.map(item => ({ label: item?.name, value: item?.id })),
      ],
      submitOnBlur,
    },
    {
      label: "Secondary Type",
      name: "type2Id",
      type: "multi-select",
      options: [
        { label: "All", value: null },
        ...contactTypes.map(item => ({ label: item?.name, value: item?.id })),
      ],
      submitOnBlur,
    },
    {
      data: salesUser,
      edit,
      label: "Primary Sales Contact",
      name: "salesUser",
      type: "salesUser",
      submitOnBlur,
    },
    {
      data: salesUser2,
      edit,
      label: "2nd Sales Contact",
      name: "salesUser2",
      placeHolder: "Click icon to search",
      type: "salesUser",
      submitOnBlur,
    },
    {
      label: "Exhibition Liason",
      name: "exhUserId",
      options: [
        ...entities.map(item => ({ label: item?.name, value: item?.id })),
      ],
      placeholder: "Select Exhibition Liason",
      type: "multi-select",
      submitOnBlur,
    },
  ];

  const addSalesUser = (activeSalesUser, name) => ({
    scale: "md",
    hideCloseButton: true,
    closeOnBackdrop: true,
    body: SalesUserComponent,
    userName: formik.values?.name,
    activeSalesUser,
    loading,
    updateContact,
    isPrimarySalesUser: name === "salesUser",
  });

  const renderSalesUser = (inputData, key) => {
    return (
      <div key={key} className="component-table-row w-full">
        <div className="flex flex-row border-b border-black py-2">
          <div className="flex flex-1 flex-col">
            {inputData.data?.name || inputData?.placeHolder}
          </div>
          {inputData.edit && (
            <div className="flex flex-col">
              <Modal {...addSalesUser(inputData.data, inputData.name)}>
                <IconButton variant="clean" title="Delete Method">
                  <MagnifyingGlassIcon
                    className={`h-4 w-4 cursor-pointer text-gray-400`}
                  />
                </IconButton>
              </Modal>
            </div>
          )}
        </div>
        <div className="pt-2 text-right text-xs font-bold text-black">
          {inputData.label}
        </div>
      </div>
    );
  };

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      disabled,
      formik,
      ...item,
    };

    return (
      <div key={index} className="w-full">
        {item.type === "salesUser"
          ? renderSalesUser(item, index)
          : getInput(inputProps)}
      </div>
    );
  });

  return (
    <div className="mt-10 w-full">
      <div className="mb-6 flex flex-row justify-between">
        <div className="flex flex-row items-center">
          <h1 className="mr-1 text-xl font-bold">White Cube Internal</h1>
        </div>
      </div>
      <div className="grid gap-4">{renderInputs}</div>
    </div>
  );
};

const Specifications = ({ disabled, formik, submitOnBlur = false }) => {
  const { data: countriesQuery } = useQuery(FETCH_COUNTRIES);
  const { countries: countriesCatalog } = countriesQuery || {};
  const countries = countriesCatalog || [];
  const inputs = [
    {
      label: "Mailing Label Name",
      name: "mailingname",
      placeholder: "Type to add text",
      type: "text",
      submitOnBlur,
    },
    {
      label: "Greeting",
      name: "greeting",
      placeholder: "Type to add text",
      type: "text",
      submitOnBlur,
    },
    {
      label: "Salutation",
      name: "salutation",
      placeholder: "Type to add text",
      type: "text",
      submitOnBlur,
    },
    {
      label: "Nationality",
      name: "nationality",
      options: [
        ...countries.map(item => ({ label: item?.name, value: item?.id })),
      ],
      type: "multi-select",
      placeholder: "Select a nationality",
      submitOnBlur,
    },
    {
      label: "Other Contact Details",
      name: "details",
      placeholder: "Type to add text",
      type: "textarea",
      submitOnBlur,
    },
  ];

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      disabled,
      formik,
      ...item,
    };

    return (
      <div key={index} className="flex flex-1">
        {getInput(inputProps)}
      </div>
    );
  });

  return (
    <div className="mt-10 w-full">
      <div className="mb-6 flex flex-row justify-between">
        <div className="flex flex-row items-center">
          <h1 className="mr-4 text-xl font-bold">Specifications</h1>
        </div>
      </div>
      <div className="grid gap-4">{renderInputs}</div>
    </div>
  );
};

const ContactDetails = props => {
  const {
    contactId,
    contact,
    formik,
    loading,
    updateContact,
    updateContactValue,
  } = props;

  const { hasPermission } = useUser();
  const disabled = !hasPermission("EDIT_CONTACT");

  return (
    <div className="mb-20 w-full">
      <Header
        disabled={disabled}
        leftText={formik?.values?.name}
        image={contact?.img}
        contact={contact}
        contactId={contactId}
        loadingContact={loading}
        updateContact={updateContact}
      />
      <div className="flex flex-col space-x-20 md:flex-row">
        <div className="w-full">
          <Infos disabled={disabled} formik={formik} submitOnBlur={true} />
          <ContactAddress contact={contact} />
        </div>
        <div className="w-full">
          <Methods contact={contact} />
          <MailingLists contact={contact} />
        </div>
        <div className="w-full">
          <WhiteCubeInternal
            disabled={disabled}
            formik={formik}
            loading={loading}
            updateContact={updateContactValue}
            submitOnBlur={true}
          />
        </div>
        <div className="w-full">
          <Specifications
            disabled={disabled}
            formik={formik}
            submitOnBlur={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
