import { gql } from "@apollo/client";
import { GuaranteeAccount } from "../fragments";

const CREATE_GUARANTEE_ACCOUNT = props => gql`
  mutation CreateGuaranteeAccount($input: CreateGuaranteeAccountInput!) {
    createGuaranteeAccount(input: $input) {
      success
      error
      guaranteeAccount {
       ${GuaranteeAccount(props)}
      }
    }
  }
`;

const UPDATE_GUARANTEE_ACCOUNT = props => gql`
  mutation UpdateGuaranteeAccount($input: UpdateGuaranteeAccountInput!) {
    updateGuaranteeAccount(input: $input) {
      success
      error
      guaranteeAccount {
       ${GuaranteeAccount(props)}
      }
    }
  }
`;

export { CREATE_GUARANTEE_ACCOUNT, UPDATE_GUARANTEE_ACCOUNT };
