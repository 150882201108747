import { useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, MenuButton } from "../../../components/ui";
import { FETCH_COMPANY as fetchCompany } from "../../../graphql/query/Company";
import routes from "../pages/Wizard/routes";
import NewCompany from "./Actions/NewCompany";
import useUser from "../../../hooks/useUser";

const PrimaryNav = props => {
  const { formik } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = location?.pathname.split("/");
  const id = pathnames?.length > 2 ? pathnames[2] : null;
  const { hasPermission } = useUser();

  // fetch company details if id is available
  const { data: { company } = {} } = useQuery(fetchCompany(), {
    skip: !id,
    variables: { id: +id },
  });

  // find current submodule route
  const path = pathnames[pathnames?.length - 1];
  const route = routes?.find(item => item?.href?.includes(path));
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams.get("status") || "all";
  const listId = +(
    queryParams.get("listId") ||
    (status === "selectListWorking" ? queryParams.get("workingListId") : null)
  );

  const selected = Object.keys(formik?.values?.selected?.ids || {});
  const ids = selected?.map(key => {
    const obj = formik?.values?.selected?.ids?.[key];
    return obj?.item?.id || obj?.id;
  });
  const select = formik?.values?.selected?.select;

  const statusMap = {
    all: "All Companies",
    selectListWorking: "Select List (Working)",
  };
  const label = statusMap[status] || "All Companies";
  // prepare steps for breadcrumbs
  const steps = [
    { label, onClick: () => navigate(`/companies?status=${status}`) },
    ...(company
      ? [
          {
            label: company?.name,
            onClick: () => navigate(`${company?.id}/details`),
          },
          {
            label: route?.name,
            onClick: () => navigate(`${company?.id}/${route?.href}`),
          },
        ]
      : []),
  ];

  const breadcrumbProps = {
    currentStepIndex: steps?.length - 1,
    onChange: index => steps[index]?.onClick(),
    steps: steps?.map(item => item?.label),
  };

  const defaultActionProps = {
    closeOnBackdrop: false,
    hideCloseButton: true,
    ids: ids.length ? ids : [+id],
    listId,
    onClose: () => {
      formik?.resetForm();
    },
    scale: "md",
    select,
  };

  const actionMenuProps = {
    label: "Actions",
    options: [
      {
        label: "New Company",
        disabled: !hasPermission("CREATE_COMPANY"),
        modalProps: {
          body: NewCompany,
          disabled: !hasPermission("CREATE_COMPANY"),
          ...defaultActionProps,
        },
      },
    ],
  };

  return (
    <div className="flex items-center justify-between border-b px-8 py-5">
      <Breadcrumb {...breadcrumbProps} />
      <div className="flex justify-end gap-4">
        <MenuButton {...actionMenuProps} />
      </div>
    </div>
  );
};

export default PrimaryNav;
