import artworkFrag from "./Artwork";
import EditionType from "./EditionType";
import instance from "./Instance";

const Edition = ({
  artwork = true,
  availableNumbers = false,
  instances = false,
  type = false,
} = {}) => `
  id
  ap
  ${
    artwork
      ? `artwork {
      ${artworkFrag(artwork)}
    }`
      : ""
  }
  ${availableNumbers ? `availableNumbers` : ""}
  ${
    instances
      ? `instances {
      ${instance(instances)}
    }`
      : ""
  }
  ${
    type
      ? `type {
      ${EditionType}
    }`
      : ""
  }
  year
  number
`;

export default Edition;
