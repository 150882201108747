import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";
import { IconButton, Modal } from "../../../../../../../components/ui";
import UpdateImage from "./UpdateImage";

const Actions = props => {
  const modalProps = {
    body: UpdateImage,
    closeOnBackdrop: false,
    hideCloseButton: true,
    scale: "md",
    ...props,
  };

  return (
    <div className="user-list-actions flex text-right text-sm font-medium">
      <div className="grid grid-cols-2 gap-4">
        <Modal {...modalProps}>
          <IconButton variant="clean" title="Update Image">
            <ArrowUpOnSquareIcon
              className={`h-4 w-4 cursor-pointer text-gray-400`}
            />
          </IconButton>
        </Modal>
      </div>
    </div>
  );
};

export default Actions;
