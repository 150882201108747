/* eslint-disable max-len */
const ArtistIcon = props => (
  <svg
    width={34}
    height={34}
    viewBox="0 0 1531 1533"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    fill="none"
    stroke="currentColor"
    {...props}
  >
    <g
      transform="translate(0.000000,1533.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M14206 15224 c-83 -18 -165 -51 -250 -98 -92 -51 -107 -64 -376 -320
-47 -45 -132 -126 -190 -180 -58 -55 -145 -138 -195 -186 -133 -126 -278 -265
-475 -450 -96 -91 -267 -253 -380 -360 -113 -107 -286 -271 -385 -365 -99 -93
-234 -221 -300 -284 -119 -115 -219 -209 -390 -371 -50 -47 -135 -128 -190
-180 -55 -52 -226 -214 -380 -360 -154 -146 -302 -287 -330 -315 -27 -27 -390
-482 -805 -1010 -415 -527 -854 -1085 -976 -1240 l-220 -280 -98 -7 c-313 -22
-610 -182 -918 -492 -211 -213 -294 -368 -433 -809 -99 -313 -163 -433 -307
-573 -89 -87 -171 -144 -313 -219 -50 -26 -106 -65 -127 -87 -115 -123 -75
-336 77 -411 73 -36 401 -121 640 -166 360 -68 554 -85 892 -78 401 8 667 63
924 192 368 185 686 593 794 1020 28 109 50 309 41 373 -5 39 -3 49 12 59 18
11 179 151 967 838 662 577 918 801 1196 1045 l268 235 847 995 c466 547 1165
1368 1553 1824 388 455 721 851 740 879 46 68 105 197 127 277 29 102 27 359
-4 463 -91 314 -324 545 -638 633 -84 24 -308 28 -398 8z m314 -537 c77 -28
168 -121 200 -202 42 -108 29 -249 -32 -338 -30 -45 -2919 -3437 -2927 -3437
-7 0 -845 818 -849 829 -5 14 3268 3111 3312 3133 94 49 190 54 296 15z
m-3141 -4361 c0 -6 -53 -56 -117 -111 -63 -55 -260 -226 -436 -380 -177 -154
-568 -495 -869 -758 l-548 -478 -197 189 c-108 105 -219 213 -246 240 l-48 50
448 568 c246 313 605 769 797 1014 193 245 359 455 370 467 l20 22 413 -406
c228 -223 414 -411 413 -417z m-2855 -1674 c95 -45 186 -112 282 -208 137
-137 203 -300 204 -501 0 -255 -105 -500 -309 -716 -153 -162 -348 -255 -636
-303 -135 -23 -541 -26 -705 -6 -132 16 -352 51 -358 57 -3 2 24 37 59 77 153
178 236 343 359 718 125 380 169 460 338 622 150 143 311 247 437 283 32 9 88
13 155 12 95 -2 112 -6 174 -35z"
      />
      <path
        d="M6010 14350 c-929 -87 -1902 -453 -2740 -1032 -63 -43 -189 -128
-280 -187 -1006 -659 -1864 -1736 -2418 -3036 -276 -649 -406 -1171 -474
-1910 -20 -221 -17 -951 5 -1195 63 -687 194 -1307 408 -1943 508 -1505 1419
-2800 2591 -3680 600 -450 1282 -799 1974 -1007 1019 -308 2168 -388 3274
-230 1148 164 2162 585 2944 1220 306 248 663 612 929 947 717 903 1146 1912
1228 2891 15 178 6 596 -15 738 -109 725 -419 1249 -946 1599 -256 169 -530
269 -815 296 -293 27 -617 -41 -927 -196 -290 -146 -353 -210 -352 -360 1 -33
8 -76 16 -96 41 -94 138 -159 241 -159 67 0 98 11 204 76 143 87 311 156 473
196 34 8 117 13 220 13 149 0 175 -3 262 -27 184 -52 366 -152 544 -301 476
-396 673 -1123 539 -1991 -141 -907 -636 -1880 -1361 -2670 -131 -143 -342
-351 -469 -461 -585 -511 -1373 -895 -2240 -1095 -1204 -278 -2573 -223 -3700
147 -1373 451 -2547 1421 -3385 2798 -497 815 -863 1796 -1024 2740 -69 408
-96 697 -103 1105 -8 446 17 793 82 1169 93 533 380 1294 711 1888 318 570
634 993 1059 1419 300 300 507 471 840 692 93 62 223 149 287 195 535 374
1174 670 1770 817 738 182 1422 168 2063 -45 347 -115 691 -291 971 -498 131
-97 357 -321 437 -434 76 -107 159 -267 206 -396 44 -123 74 -164 141 -201
169 -92 372 16 387 207 7 78 -29 189 -123 387 -237 495 -684 904 -1339 1224
-384 188 -779 308 -1206 367 -123 17 -218 22 -474 24 -176 2 -363 -1 -415 -5z"
      />
      <path
        d="M5273 12089 c-359 -36 -719 -230 -946 -507 -214 -261 -328 -584 -328
-926 -1 -704 483 -1291 1171 -1421 127 -24 361 -25 485 -1 578 109 1018 537
1147 1116 20 91 23 132 23 305 0 156 -4 218 -18 283 -114 519 -472 925 -957
1086 -180 59 -396 84 -577 65z m292 -534 c387 -68 690 -398 736 -801 21 -188
-21 -391 -118 -564 -62 -109 -206 -259 -310 -319 -312 -184 -666 -173 -963 29
-64 44 -182 164 -229 233 -120 176 -179 423 -152 631 30 226 123 410 284 562
203 194 477 277 752 229z"
      />
      <path
        d="M3550 8424 c-178 -23 -350 -77 -509 -159 -139 -73 -255 -160 -367
-277 -106 -112 -177 -212 -243 -345 -284 -570 -172 -1250 279 -1693 197 -193
425 -314 710 -378 58 -13 123 -17 285 -16 201 0 215 2 325 31 275 73 486 197
682 401 189 198 314 438 375 722 26 121 26 430 0 550 -127 586 -541 1014
-1102 1136 -108 24 -347 39 -435 28z m302 -538 c354 -68 639 -352 715 -711 24
-114 19 -300 -11 -417 -155 -604 -841 -875 -1351 -536 -180 120 -317 316 -376
538 -29 110 -32 328 -6 432 75 303 270 534 543 645 47 19 115 40 152 48 90 18
244 18 334 1z"
      />
      <path
        d="M9605 5570 c-322 -36 -626 -183 -855 -412 -254 -255 -399 -591 -416
-963 -8 -156 6 -278 47 -433 70 -266 195 -479 395 -672 268 -259 601 -394 974
-393 169 0 280 17 432 67 215 70 399 184 564 350 214 215 341 459 400 771 26
135 24 387 -5 525 -111 527 -471 937 -967 1099 -166 54 -403 79 -569 61z m378
-554 c243 -72 450 -245 560 -468 204 -416 75 -919 -303 -1175 -481 -325 -1138
-102 -1330 453 -49 139 -64 329 -39 470 65 367 340 657 699 735 121 27 292 20
413 -15z"
      />
      <path
        d="M5515 4999 c-600 -93 -1064 -537 -1190 -1139 -64 -307 -17 -657 127
-943 171 -341 470 -600 828 -717 155 -51 247 -64 435 -64 256 0 423 40 644
155 331 171 579 460 696 812 59 179 70 250 70 467 0 150 -4 214 -18 279 -64
299 -193 539 -401 747 -202 202 -431 326 -716 389 -88 20 -384 29 -475 14z
m403 -540 c321 -74 581 -339 664 -675 16 -67 21 -120 21 -214 0 -164 -24 -266
-100 -420 -52 -105 -66 -124 -162 -221 -141 -141 -276 -218 -450 -255 -87 -18
-262 -18 -350 1 -531 112 -844 668 -670 1190 94 281 298 484 582 580 143 48
301 53 465 14z"
      />
    </g>
  </svg>
);

export default ArtistIcon;
