import { useState } from "react";
import { Route, Routes as RouterRoutes, useLocation } from "react-router-dom";

import { Header, SimpleSidebar } from "../components/ui";
import PDFGenerator from "../modules/PDFGenerator";
import Admin from "../modules/Admin";
import Email from "../modules/Email";
import Wizard from "../modules/PDFGenerator/pages/Wizard";
import Contact from "../modules/Contact";
import Artwork from "../modules/Artwork";
import Instance from "../modules/Instance";
import Invoice from "../modules/Invoice";
import Consignment from "../modules/Consignment";
import Offer from "../modules/Offer";
import SelectLists from "../modules/SelectList";
import Shipment from "../modules/Shipment";
import Warehouse from "../modules/Warehouse";
import Jobs from "../modules/Jobs";
import Reports from "../modules/Reports";
import Artist from "../modules/Artist";
import Company from "../modules/Company";

const routes = [
  {
    path: "create-pdf/*",
    element: Wizard,
  },
  {
    path: "admin/*",
    element: Admin,
  },
  {
    path: "email/*",
    element: Email,
  },
  {
    path: "contacts/*",
    element: Contact,
  },
  {
    path: "companies/*",
    element: Company,
  },
  {
    path: "artworks/*",
    element: Artwork,
  },
  {
    path: "instance/*",
    element: Instance,
  },
  {
    path: "invoice/*",
    element: Invoice,
  },
  {
    path: "consignment/*",
    element: Consignment,
  },
  {
    path: "offer/*",
    element: Offer,
  },
  {
    path: "shipments/*",
    element: Shipment,
  },
  {
    path: "warehouse/*",
    element: Warehouse,
  },
  {
    path: "jobs/*",
    element: Jobs,
  },
  {
    path: "reports/*",
    element: Reports,
  },
  {
    path: "*",
    element: PDFGenerator,
  },
  {
    path: "select-lists/*",
    element: SelectLists,
  },
  {
    path: "artist/*",
    element: Artist,
  },
];

const Auth = props => {
  const [open, setOpen] = useState();
  const location = useLocation();
  const { pathname } = location || {};
  const paths = pathname?.split("/");
  const path = paths[paths?.length - 1];

  const headerProps = {
    open,
    setOpen,
  };

  const bodyProps = {
    open,
    setOpen,
  };

  const simpleSideBar = {
    open,
    setOpen,
    path,
  };

  return (
    <div className="flex h-full w-full">
      <SimpleSidebar {...simpleSideBar} />
      <div
        className={`flex flex-1 flex-col ${open ? "w-[calc(100vw-18rem)]" : "w-[calc(100vw-6rem)]"}`}
      >
        <Header {...headerProps} className="fixed left-0 top-0 h-[64px]" />
        <div className="h-[calc(100%-64px)] w-full flex-1 overflow-y-scroll">
          <RouterRoutes>
            {routes?.map((item, index) => {
              return (
                <Route
                  key={index}
                  path={item?.path}
                  element={<item.element {...bodyProps} />}
                />
              );
            })}
          </RouterRoutes>
        </div>
      </div>
    </div>
  );
};

export default Auth;
