import { useQuery } from "@apollo/client";
import { Button, getInput } from "../../../../../../components/ui";
import { FETCH_CONTACT_ADDRESS as fetchContactAddress } from "../../../../../../graphql/query/ContactAddress";
import { FETCH_COUNTRIES } from "../../../../../../graphql/query/Country";
import { FETCH_CONTACT_ATTRIBUTES as fetchContactAttributes } from "../../../../../../graphql/query/ContactAttribute";
import { FETCH_CONTACT_ATTRIBUTE_TYPES } from "../../../../../../graphql/query/ContactAttributeType";
import { Table } from "../../../../../../components/Functional";
import useUser from "../../../../../../hooks/useUser";
import { UPDATE_CONTACT_ATTRIBUTE } from "../../../../../../graphql/mutation/ContactAttribute";

const Infos = ({ disabled, formik, submitOnBlur = false }) => {
  const inputs = [
    {
      label: "ID #",
      name: "id",
      type: "text",
      disabled: true,
      submitOnBlur,
    },
    {
      label: "Contact",
      name: "contactId",
      type: "contactSearch",
      submitOnBlur,
      disabled,
    },
    {
      label: "Represented",
      name: "represented",
      options: [
        { label: "Represented", value: 0 },
        { label: "External", value: 1 },
        { label: "Secondary Market", value: 2 },
        { label: "Not Represented", value: 3 },
      ],
      type: "multi-select",
      submitOnBlur,
      disabled,
    },
    {
      name: "confidential",
      type: "checkbox",
      singleCheckbox: true,
      options: [{ label: "Confidential", value: -1 }],
      disabled,
      submitOnChange: true,
    },
    {
      label: "Name",
      name: "name",
      type: "text",
      submitOnBlur,
      disabled,
    },
    {
      label: "Copyright",
      name: "copyright",
      type: "text",
      submitOnBlur,
      disabled,
    },
    {
      label: "Artist Mailing List",
      name: "mailingList",
      type: "text",
      submitOnBlur,
      disabled,
    },
    {
      name: "droitdesuite",
      type: "checkbox",
      singleCheckbox: true,
      options: [{ label: "Droit de Suite", value: -1 }],
      disabled,
      submitOnChange: true,
    },
  ];

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      disabled,
      formik,
      ...item,
    };

    return (
      <div key={index} className="w-full">
        {getInput(inputProps)}
      </div>
    );
  });

  return (
    <div className="my-10 grid w-full grid-cols-4 items-center gap-x-20 gap-y-4">
      {renderInputs}
    </div>
  );
};

const ContactAddress = props => {
  const { artist } = props;
  const { data: { contactAddress } = {} } = useQuery(fetchContactAddress, {
    variables: {
      contactId: artist?.contact?.id,
      classification: "DEFAULT",
    },
  });

  return (
    <div className="rounded-[0.25em] border border-gray-400 p-4">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-lg font-semibold">Primary Address</h3>
        </div>
        <Button
          label="Edit"
          onClick={() =>
            window.open(
              `/contacts/${artist?.contact?.id}/details?status=all`,
              "_blank",
            )
          }
        />
      </div>
      {contactAddress ? (
        <div>
          <p>
            {contactAddress?.address ? (
              contactAddress?.address
            ) : (
              <span className="text-gray-400">null</span>
            )}
          </p>
          <p>
            {contactAddress?.address2 ? (
              contactAddress?.address2
            ) : (
              <span className="text-gray-400">null</span>
            )}
          </p>
          <p>
            {contactAddress?.address3 ? (
              contactAddress?.address3
            ) : (
              <span className="text-gray-400">null</span>
            )}
          </p>
          <p>
            {contactAddress?.town ? (
              contactAddress?.town
            ) : (
              <span className="text-gray-400">null</span>
            )}
          </p>
          <p>
            {contactAddress?.county ? (
              contactAddress?.county
            ) : (
              <span className="text-gray-400">null</span>
            )}
          </p>
          <p>
            {contactAddress?.country?.name ? (
              contactAddress?.country?.name
            ) : (
              <span className="text-gray-400">null</span>
            )}
          </p>
          <p>
            {contactAddress?.postcode ? (
              contactAddress?.postcode
            ) : (
              <span className="text-gray-400">null</span>
            )}
          </p>
        </div>
      ) : null}
    </div>
  );
};

const ArtistBorn = ({ disabled, formik, submitOnBlur = false }) => {
  const { data: { countries = [] } = {} } = useQuery(FETCH_COUNTRIES);

  const inputs = [
    {
      label: "Date",
      name: "bornDate",
      type: "date",
      submitOnBlur,
      disabled,
    },
    {
      label: "Town",
      name: "bornTown",
      type: "text",
      submitOnBlur,
      disabled,
    },
    {
      label: "Country",
      name: "bornCountryId",
      options: [
        ...countries.map(item => ({ label: item?.name, value: item?.id })),
      ],
      placeholder: "Select a country",
      type: "multi-select",
      submitOnBlur,
      disabled,
    },
  ];

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      disabled,
      formik,
      ...item,
    };

    return (
      <div key={index} className="w-full">
        {getInput(inputProps)}
      </div>
    );
  });

  return (
    <div className="w-full">
      <h3 className="text-lg font-semibold">Born</h3>
      <div className="mt-4 grid w-full grid-cols-2 gap-x-20 gap-y-4">
        {renderInputs}
      </div>
    </div>
  );
};

const AccountsInfo = ({ disabled, formik, submitOnBlur = false }) => {
  const inputs = [
    {
      label: "Sage Cost Centre",
      name: "sageCost",
      type: "text",
      submitOnBlur,
      disabled,
    },
    {
      label: "Default Fabrication split %",
      name: "defaultFabRate",
      type: "number",
      submitOnBlur,
      disabled,
    },
    {
      label: "Default profit split %",
      name: "defaultSplitRate",
      type: "number",
      submitOnBlur,
      disabled,
    },
    {
      label: "Max Shared Discount %",
      name: "sharedDiscount",
      type: "number",
      submitOnBlur,
      disabled,
    },
  ];

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      disabled,
      formik,
      ...item,
    };

    return (
      <div key={index} className="w-full">
        {getInput(inputProps)}
      </div>
    );
  });

  return (
    <div className="mt-20">
      <h3 className="text-lg font-semibold">Accounts Info</h3>
      <div className="mt-2 grid w-full grid-cols-4 gap-x-20 gap-y-4">
        {renderInputs}
      </div>
    </div>
  );
};

const ConsignmentTerms = ({ disabled, formik, submitOnBlur = false }) => {
  const inputs = [
    {
      label: "",
      name: "terms",
      type: "textarea",
      submitOnBlur,
      rows: 10,
      disabled,
    },
  ];

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      disabled,
      formik,
      ...item,
    };

    return (
      <div key={index} className="w-full">
        {getInput(inputProps)}
      </div>
    );
  });

  return (
    <div className="mt-10">
      <h3 className="text-lg font-semibold">Consignment Terms</h3>
      <div className="w-full">{renderInputs}</div>
    </div>
  );
};

const ContactAttributes = props => {
  const { artist } = props;
  const { data: { contactAttributeTypes = [] } = {} } = useQuery(
    FETCH_CONTACT_ATTRIBUTE_TYPES,
  );
  const { hasPermission } = useUser();

  const disableConfig = {
    disabled: true,
    inputClassName: "!bg-white !text-gray-400 !border-gray-400",
  };

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "contactAttributes",
    headers: [
      {
        label: "Type",
        name: "typeId",
        type: "input",
        inputType: "multi-select",
        options: contactAttributeTypes?.map(item => ({
          label: item.description,
          value: item.id,
        })),
        ...(!hasPermission("EDIT_CONTACT_ATTRIBUTE") ? disableConfig : {}),
      },
      {
        inputType: "text",
        label: "Description",
        name: "description",
        type: "input",
        ...(!hasPermission("EDIT_CONTACT_ATTRIBUTE") ? disableConfig : {}),
      },
    ],
    FETCH_QUERY: fetchContactAttributes({ type: {} }),
    UPDATE_MUTATION: UPDATE_CONTACT_ATTRIBUTE(),
    REFETCH_QUERIES: ["FetchContactAttributes"],
    variables: {
      contactId: artist?.contact?.id,
    },
    className: "table-fixed",
  };

  return (
    <div className="mt-10">
      <h3 className="text-lg font-semibold">Notes</h3>
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

const ArtistDetails = props => {
  const { artist, formik } = props;
  const { hasPermission } = useUser();

  return (
    <div className="mb-20 w-full">
      <h1 className="text-xl font-bold">{artist?.name}</h1>
      <div>
        <Infos
          formik={formik}
          submitOnBlur={true}
          disabled={!hasPermission("UPDATE_ARTIST")}
        />
      </div>
      <div className="grid grid-cols-2 gap-x-20">
        <ContactAddress artist={artist} />
        <ArtistBorn
          formik={formik}
          submitOnBlur={true}
          disabled={!hasPermission("UPDATE_ARTIST")}
        />
      </div>
      <AccountsInfo
        formik={formik}
        submitOnBlur={true}
        disabled={!hasPermission("UPDATE_ARTIST")}
      />
      <ConsignmentTerms
        formik={formik}
        submitOnBlur={true}
        disabled={!hasPermission("UPDATE_ARTIST")}
      />
      <ContactAttributes artist={artist} />
    </div>
  );
};

export default ArtistDetails;
