import { useEffect } from "react";
import { convertToFilter, makeObservable } from "../../../util";
import { useFormik } from "formik";

const artistFilterStore = makeObservable({});

const useArtistFilters = () => {
  const formik = useFormik({
    initialValues: {
      represented: { operator: "in" },
    },
  });

  const filterKeys = [];

  const skipKeys = ["limit", "offset"];

  useEffect(() => {
    artistFilterStore.set(
      convertToFilter({ values: formik.values, filterKeys, skipKeys }),
    );
  }, [formik.values]);

  return {
    formik,
  };
};

export { artistFilterStore, useArtistFilters };
