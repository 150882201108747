import { useMutation } from "@apollo/client";
import { Button, Modal } from "../../../../../../../components/ui";
import { GridInputs } from "../../../../../../../components/Functional";
import { useFormik } from "formik";
import { FETCH_GUARANTEE_ACCOUNTS_V2 as fetchGuaranteeAccountsV2 } from "../../../../../../../graphql/query/GuaranteeAccount";
import { CREATE_GUARANTEE_ACCOUNT } from "../../../../../../../graphql/mutation/GuaranteeAccount";
import { CreateEditFormInputs } from "./CreateEditFormInputs";
import * as Yup from "yup";

const AddCustomsAccountBody = props => {
  const { closeModal, company } = props || {};

  const [createGuaranteeAccount, { loading }] = useMutation(
    CREATE_GUARANTEE_ACCOUNT({ contact: {} }),
    {
      refetchQueries: [fetchGuaranteeAccountsV2({ contact: {} })],
    },
  );

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      number: Yup.string().required("Please enter a customs code"),
    }),
    onSubmit: async values => {
      createGuaranteeAccount({
        variables: {
          input: {
            companyId: company.id,
            ...values,
          },
        },
      }).then(resp => {
        const {
          data: { createGuaranteeAccount: { success } = {} },
        } = resp || {};
        if (success) {
          formik.resetForm();
          closeModal();
        }
      });
    },
  });

  const inputProps = {
    formik,
    ...CreateEditFormInputs,
  };

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Add a new customs account</div>
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik.resetForm();
            }}
          />
          <Button
            label={"Create"}
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="grid">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

const AddCustomsAccountModal = props => {
  const modalProps = {
    body: AddCustomsAccountBody,
    closeOnBackdrop: false,
    hideCloseButton: true,
    scale: "md",
    ...props,
  };

  return (
    <Modal {...modalProps}>
      <Button label="Add Customs Account" />
    </Modal>
  );
};

export { AddCustomsAccountModal, AddCustomsAccountBody };
