import { useMutation } from "@apollo/client";
import { GridInputs } from "../../../../../../../components/Functional";
import { UPDATE_COMPANY as updateCompanyMutation } from "../../../../../../../graphql/mutation/Company";
import { TrashIcon } from "@heroicons/react/24/outline";
import { IconButton, Button } from "../../../../../../../components/ui";
import { useFormik } from "formik";

const UpdateImage = props => {
  const { closeModal, company, values } = props || {};

  const [updateCompany, { loading }] = useMutation(
    updateCompanyMutation({ images: {} }),
  );

  const ImgInputLabel = ({ label, name, formik }) => {
    return (
      <div className="flex justify-between">
        <div className="mb-2 text-lg font-bold">{label}</div>
        <IconButton variant="clean" title="Delete Role">
          <TrashIcon
            className={`h-4 w-4 cursor-pointer text-gray-400`}
            onClick={() =>
              formik?.setFieldValue(name, { url: null, file: null })
            }
          />
        </IconButton>
      </div>
    );
  };

  const formik = useFormik({
    initialValues: company
      ? {
          header: { url: company?.images?.header },
          footer: { url: company?.images?.footer },
          logo: { url: company?.images?.logo },
        }
      : {},
    enableReinitialize: true,
    onSubmit: async values => {
      const { header, footer, logo } = values || {};

      updateCompany({
        variables: {
          input: {
            id: company.id,
            header: header?.file,
            footer: footer?.file,
            logo: logo?.file,
          },
        },
      }).then(resp => {
        const {
          data: { updateCompany: { success } = {} },
        } = resp || {};
        if (success) {
          formik.resetForm();
          closeModal();
        }
      });
    },
  });

  const inputs = {
    className: "grid grid-cols-1",
    inputs: [
      {
        className: "grid grid-cols-1 gap-8",
        inputs: [
          {
            label: (
              <ImgInputLabel
                {...{ formik, name: values.key, label: values.type }}
              />
            ),
            name: values.key,
            type: "img",
            w: "w-full",
            h: "h-96",
            value: formik?.values?.[values.key]?.url,
            onChange: e => {
              const files = e?.target?.files;
              const url = URL.createObjectURL(files[0]);
              formik.setFieldValue(values.key, {
                url,
                file: files?.[0],
              });
            },
          },
        ],
      },
    ],
  };

  const inputProps = {
    ...inputs,
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Edit {values.type} image</div>
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik.resetForm();
            }}
          />
          <Button
            label={"Update"}
            loading={loading}
            disabled={loading}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="grid">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default UpdateImage;
