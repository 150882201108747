import contactFrag from "./Contact";
import Country from "./Country";

const Artist = ({ contact = false } = {}) => `
  id
  bornCountry {
    ${Country}
  }
  bornCountryId
  bornDate
  bornTown
  ${
    contact
      ? `contact {
      ${contactFrag(contact)}
    }`
      : ""
  }
  confidential
  contactId
  copyright
  defaultFabRate
  defaultSplitRate
  droitdesuite
  mailingList
  name
  represented
  sageCost
  sharedDiscount
  terms
`;

export default Artist;
