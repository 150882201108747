import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button, Modal } from "../../../../../../../components/ui";
import { GridInputs } from "../../../../../../../components/Functional";

import { FETCH_CURRENCIES } from "../../../../../../../graphql/query/Currency";
import { FETCH_JOB_ITEMS as fetchJobItems } from "../../../../../../../graphql/query/JobItem";
import { SPLIT_COST } from "../../../../../../../graphql/mutation/Fabrication";

const AddCostModalBody = props => {
  const { closeModal, jobId } = props || {};

  const [instances, setInstances] = useState([]);

  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);
  const { data: { jobItems: { edges: jobItems = [] } = {} } = {} } = useQuery(
    fetchJobItems({ instance: true }),
    {
      skip: !jobId,
      variables: { input: { jobId: { value: jobId, operator: "eq" } } },
    },
  );

  const [
    splitCost,
    { data: { splitCost: { error: splitCostError } = {} } = {}, loading },
  ] = useMutation(SPLIT_COST, { refetchQueries: ["FetchFabricationsV2"] });

  useEffect(() => {
    if (jobItems?.length) {
      const uniqueInstanceIds = [];
      const itemInstances = jobItems?.map(item => ({
        id: item?.instance?.id,
        name: item?.instance?.name,
      }));
      const instancesArray = itemInstances
        .filter(item => item?.id)
        .filter(instance => {
          if (!uniqueInstanceIds.includes(instance.id)) {
            uniqueInstanceIds.push(instance.id);
            return true;
          }
          return false;
        })
        .sort((a, b) => a.id - b.id);
      instancesArray?.length && setInstances(instancesArray);
    }
  }, [jobItems]);

  const handleClick = () => {
    const {
      amount,
      currencyId,
      date,
      description,
      instanceId,
      invoice,
      modalSection,
      po,
      tax,
    } = formik.values;
    splitCost({
      variables: {
        input: {
          amount,
          currencyId,
          date,
          description,
          invoice,
          ...(instanceId && modalSection == "individual" ? { instanceId } : {}),
          jobId,
          po,
          tax,
        },
      },
    }).then(({ data }) => {
      const { splitCost: { success } = {} } = data;
      if (success) {
        formik.resetForm();
        closeModal();
        formik?.setFieldValue("modalSection", "");
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      instanceId: null,
      modalSection: "",
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      date: Yup.date().required("Please select a date."),
      description: Yup.string().trim().required("Please enter a description."),
      currencyId: Yup.number()
        .required("Please select a currency.")
        .typeError("Please select a currency."),
      amount: Yup.number()
        .typeError("Please enter a valid amount.")
        .min(1, "The amount must be greater than 0")
        .required("Please enter an amount."),
      tax: Yup.number()
        .typeError("Please enter a valid tax.")
        .min(0, "The tax must be greater than 0"),
      instanceId: Yup.number()
        .nullable()
        .when("modalSection", {
          is: "individual",
          then: schema =>
            schema
              .required("Please select an instance")
              .typeError("Please select an instance"),
        }),
    }),
    onSubmit: handleClick,
  });

  const inputs = {
    className: "grid grid-cols-3 gap-6",
    inputs: [
      { label: "Date Filled", name: "date", type: "date" },
      { label: "Order #", name: "po", type: "text" },
      { label: "Invoice", name: "invoice", type: "text" },
      {
        label: "Currency",
        name: "currencyId",
        type: "multi-select",
        options: currencies?.map(item => ({
          label: item?.code,
          value: item?.id,
        })),
      },
      { label: "Net Amount", name: "amount", type: "number-currency" },
      { label: "Tax", name: "tax", type: "number-currency" },
      { label: "Description", name: "description", type: "text" },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  const IndividualScreen = () => {
    const instanceInputs = {
      className: "grid grid-cols-3 gap-6",
      inputs: [
        {
          label: "Instance",
          name: "instanceId",
          type: "multi-select",
          options: instances?.map(instance => ({
            label: instance?.name,
            value: instance?.id,
          })),
        },
      ],
    };

    const instanceInputProps = {
      formik,
      ...instanceInputs,
    };

    return (
      <>
        <p className="mb-8">
          Select which associated Instance this cost belongs to
        </p>
        <GridInputs {...instanceInputProps} />
      </>
    );
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>
            {formik?.values?.modalSection == "individual"
              ? "Allocate Individual Cost"
              : formik?.values?.modalSection == "split"
                ? "Split Cost"
                : "Add Cost"}
          </div>
        </div>
        <div className="flex gap-4">
          {!formik?.values?.modalSection && (
            <>
              <Button
                label="Cancel"
                action="default"
                onClick={() => {
                  closeModal();
                  formik?.resetForm?.();
                }}
              />
              <Button
                label={"Individual"}
                disabled={!formik.isValid}
                onClick={async () => {
                  formik.setFieldValue("modalSection", "individual");
                  await formik?.validateField?.("instanceId");
                }}
              />
              <Button
                label={"Split"}
                disabled={!formik.isValid}
                onClick={() => formik?.setFieldValue("modalSection", "split")}
              />
            </>
          )}
          {formik?.values?.modalSection && (
            <Button
              label={"Go Back"}
              action="default"
              onClick={() => formik?.setFieldValue("modalSection", "")}
            />
          )}
          {formik?.values?.modalSection == "individual" && (
            <Button
              label={"Save"}
              disabled={loading || !formik.isValid}
              onClick={formik.submitForm}
            />
          )}
          {formik?.values?.modalSection == "split" && (
            <Button
              label={"Split"}
              disabled={loading || !formik.isValid}
              onClick={formik.submitForm}
            />
          )}
        </div>
      </div>
      {splitCostError && (
        <small className="text-sm font-medium text-red-600">
          {splitCostError}
        </small>
      )}
      <div className="my-4 grid">
        {formik?.values?.modalSection == "individual" ? (
          <IndividualScreen />
        ) : formik?.values?.modalSection == "split" ? (
          <p className="mb-8">
            This cost will be split across {instances.length} attached items
          </p>
        ) : (
          <GridInputs {...inputProps} />
        )}
      </div>
    </div>
  );
};

const AddCost = props => {
  const { jobId } = props || {};

  const modalProps = {
    body: AddCostModalBody,
    closeOnBackdrop: true,
    jobId,
    hideCloseButton: true,
    scale: "md",
  };

  return (
    <Modal {...modalProps}>
      <Button label="Add Cost" action="black" />
    </Modal>
  );
};

export default AddCost;
