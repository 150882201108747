import { gql } from "@apollo/client";
import { Company as company } from "../fragments";

const CREATE_COMPANY = props => gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      success
      error
      company {
        ${company(props)}
      }
    }
  }
`;

const UPDATE_COMPANY = props => gql`
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      success
      error
      company {
        ${company(props)}
      }
    }
  }
`;

const DELETE_COMPANY = gql`
  mutation deleteCompany($input: DeleteCompanyInput!) {
    deleteCompany(input: $input) {
      success
      error
    }
  }
`;

export { CREATE_COMPANY, UPDATE_COMPANY, DELETE_COMPANY };
