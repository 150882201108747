import useUser from "../../../../../../hooks/useUser";
import { getInput } from "../../../../../../components/ui";
import SectionHeader from "../../components/SectionHeader";

const CompanyTerms = ({ disabled, formik, submitOnBlur = false }) => {
  const inputs = [
    {
      label: "Consignment Terms",
      name: "consignmentTerms",
      type: "textarea",
      disabled: disabled,
      submitOnBlur,
    },
    {
      label: "Loan terms",
      name: "loanTerms",
      type: "textarea",
      disabled: disabled,
      submitOnBlur,
    },
    {
      label: "Consignment Credit",
      name: "consignmentCredit",
      type: "text",
      disabled: disabled,
      submitOnBlur,
    },
    {
      label: "Photographic Material  Credit",
      name: "photoCredit",
      type: "text",
      disabled: disabled,
      submitOnBlur,
    },
    {
      label: "Consignment Sign Off",
      name: "consignmentSignOff",
      type: "textarea",
      disabled: disabled,
      submitOnBlur,
    },
    {
      label: "Loan Sign Off",
      name: "loanSignOff",
      type: "textarea",
      disabled: disabled,
      submitOnBlur,
    },
  ];

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      disabled,
      formik,
      ...item,
    };

    return (
      <div key={index} className="w-full">
        {getInput(inputProps)}
      </div>
    );
  });

  return <div className="grid w-full gap-8">{renderInputs}</div>;
};

export const ConsignmentTerms = props => {
  const { formik, company } = props;

  const { hasPermission } = useUser();
  const disabled = !hasPermission("UPDATE_COMPANY_TERMS");
  return (
    <div className="w-full">
      <SectionHeader title="Consignment Terms" />
      <CompanyTerms
        company={company}
        disabled={disabled}
        formik={formik}
        submitOnBlur={true}
      />
    </div>
  );
};

export default ConsignmentTerms;
