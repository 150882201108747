import * as React from "react";
import SectionHeader from "../../components/SectionHeader";
import { GridInputs } from "../../../../../../components/Functional";
import useUser from "../../../../../../hooks/useUser";

const ArtworkTechnical = props => {
  const { formik } = props || {};
  const { hasPermission } = useUser();

  const inputs = {
    className: "grid grid-cols-4 gap-16",
    inputs: [
      {
        className: "col-span-1 gap-4",
        inputs: [
          {
            label: "Specifications",
            name: "installation",
            rows: 6,
            type: "textarea",
            submitOnBlur: true,
          },
          {
            disabled: !hasPermission("UPDATE_ARTWORK_AUTHENTICITY"),
            label: "Authenticity",
            name: "authenticity",
            rows: 6,
            type: "textarea",
            submitOnBlur: true,
          },
        ],
      },
      {
        className: "col-span-1 gap-4",
        inputs: [
          {
            label: "Maintenance Requirements",
            name: "maintenance",
            rows: 6,
            type: "textarea",
            submitOnBlur: true,
          },
          {
            label: "Packing",
            name: "packingText",
            rows: 6,
            type: "textarea",
            submitOnBlur: true,
          },
        ],
      },
      {
        className: "col-span-1 gap-4",
        inputs: [
          {
            disabled: !hasPermission("UPDATE_ARTWORK_TECHNICAL"),
            label: "Technical Dimensions",
            name: "technicalDimensions",
            rows: 6,
            type: "textarea",
            submitOnBlur: true,
          },
        ],
      },
      {
        className: "col-span-1 gap-4",
        inputs: [
          {
            label: "Old Dimensions",
            name: "dimensions",
            type: "text",
            submitOnBlur: true,
          },
          {
            disabled: true,
            label: "Link",
            name: "installationLink",
            type: "text",
            submitOnBlur: true,
          },
        ],
      },
    ],
  };

  const detailInputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="w-full">
      <SectionHeader title="Technical" />
      <div className="w-full px-16 py-8">
        <GridInputs {...detailInputProps} />
      </div>
    </div>
  );
};

export default ArtworkTechnical;
