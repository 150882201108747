import { gql } from "@apollo/client";
import { Currency as currency } from "../fragments";

const FETCH_EXCHANGE_RATES = props => gql`
  query FetchExchangeRates($input: ExchangeRateFilterInput!) {
    exchangeRates(input: $input) {
      count
      edges {
        id
        companyId
        currencyId
        currency {
          ${currency}
        }
        exchange
        updatedAt
      }
    }
  }
`;

export { FETCH_EXCHANGE_RATES };
