import { useMutation } from "@apollo/client";
import { GridInputs } from "../../../../../../../components/Functional";
import { useFormik } from "formik";
import { FETCH_GUARANTEE_ACCOUNTS_V2 as fetchGuaranteeAccountsV2 } from "../../../../../../../graphql/query/GuaranteeAccount";
import { UPDATE_GUARANTEE_ACCOUNT } from "../../../../../../../graphql/mutation/GuaranteeAccount";
import { Button } from "../../../../../../../components/ui";
import { CreateEditFormInputs } from "./CreateEditFormInputs";
import * as Yup from "yup";

const UpdateCustomsAccount = props => {
  const { closeModal, companyId, values } = props || {};

  const [updateGuaranteeAccount, { loading }] = useMutation(
    UPDATE_GUARANTEE_ACCOUNT({ contact: {} }),
    {
      refetchQueries: [fetchGuaranteeAccountsV2({ contact: {} })],
    },
  );

  const formik = useFormik({
    initialValues: values,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      number: Yup.string().required("Please enter a customs code"),
    }),
    onSubmit: async values => {
      const omitKeys = (obj, arr) =>
        Object.fromEntries(
          Object.entries(obj).filter(([k]) => !arr.includes(k)),
        );

      const filteredValues = omitKeys(values, [
        "__typename",
        "isnewData",
        "rawFields",
      ]);

      updateGuaranteeAccount({
        variables: {
          input: {
            ...filteredValues,
            companyId: companyId,
          },
        },
      }).then(resp => {
        const {
          data: { updateGuaranteeAccount: { success } = {} },
        } = resp || {};
        if (success) {
          formik.resetForm();
          closeModal();
        }
      });
    },
  });

  const inputProps = {
    formik,
    ...CreateEditFormInputs,
  };

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Edit customs account</div>
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik.resetForm();
            }}
          />
          <Button
            label={"Update"}
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="grid">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default UpdateCustomsAccount;
