import artwork from "./Artwork";
// import contact from './Contact'
import image from "./Image";
import instance from "./Instance";
import invoice from "./Invoice";
import offer from "./Offer";
import job from "./Job";
import warehouseItem from "./WarehouseItem";
import company from "./Company";

const ListItems = ({ item = false } = {}) => `
  id
  ${
    item
      ? `item {
      __typename
      ... on Artist {
        id
        name
        bornCountry {
          name
        }
        confidential
      }
      ... on Artwork {
        ${artwork(item)}
      }
      ... on Contact {
        id
        companyName: company
        country {
          id
          name
        }
        county
        email
        img
        name
        salesUser {
          id
          name
        }
        title
        type {
          id
          name
        }
      }
      ... on Company {
        ${company(item)}
      }
      ... on Image {
        ${image(item)}
      }
      ... on Instance {
        ${instance(item)}
      }
      ... on Invoice {
        ${invoice(item)}
      }
      ... on Offer {
        ${offer(item)}
      }
      ... on WarehouseItem {
        ${warehouseItem(item)}
      }
      ... on Job {
        ${job(item)}
      }
    }`
      : ""
  }
  listId
  meta
`;

export default ListItems;
