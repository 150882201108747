import SectionHeader from "../../components/SectionHeader";
import { FETCH_BANK_ACCOUNTS_V2 as fetchBankAccountsV2 } from "../../../../../../graphql/query/BankAccount";
import { Table } from "../../../../../../components/Functional";
import { AddBankAccountModal } from "./components/AddBankAccount";
import Actions from "./components/Actions";

const BankAccounts = props => {
  const { company } = props;

  const tableProps = {
    headers: [
      {
        label: "ID",
        name: "id",
        sortKey: "id",
        type: "label",
      },
      {
        label: "Account Holder",
        name: "holder",
        type: "label",
      },
      {
        label: "Bank Name",
        name: "bank",
        type: "label",
      },
      {
        label: "Account Number",
        name: "number",
        type: "label",
      },
      {
        label: "Currency",
        name: "currency.code",
        type: "label",
      },
      {
        label: "",
        type: "custom",
        component: Actions,
        companyId: company.id,
      },
    ],
    dataKey: "bankAccountsV2",
    FETCH_QUERY: fetchBankAccountsV2({ currency: {} }),
    variables: {
      companyId: company?.id,
      limit: 50,
      offset: 0,
    },
    variant: "dynamicHeight",
    className: "sm:px-0 lg:px-0",
  };

  const AddBankAction = [
    {
      component: () => {
        return <AddBankAccountModal company={company} />;
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Bank Accounts" actions={AddBankAction} />
      <Table {...tableProps} />
    </div>
  );
};

export default BankAccounts;
