import { useMutation, useQuery } from "@apollo/client";
import { Route, Routes as RouterRoutes, useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FETCH_COMPANY as fetchCompany } from "../../../../graphql/query/Company";
import { UPDATE_COMPANY as updateCompanyMutation } from "../../../../graphql/mutation/Company";
import AuthRoute from "../../../../components/AuthRoute";
import routes from "./routes";
import { NavBar } from "../../../../components/ui";
import { Portal, getChangedFields } from "../../../../util";
import useUser from "../../../../hooks/useUser";

const Body = props => {
  return (
    <RouterRoutes>
      {routes
        ?.filter?.(item => item?.element)
        ?.map((item, index) => {
          return (
            <Route
              key={index}
              path={item?.href}
              element={
                <AuthRoute permissions={item.permissions}>
                  <item.element {...props} />
                </AuthRoute>
              }
            />
          );
        })}
    </RouterRoutes>
  );
};

const Wizard = props => {
  const { id } = useParams();
  const { data: { company } = {} } = useQuery(
    fetchCompany({
      images: {},
      contact: {
        contactAddresses: { country: {} },
      },
    }),
    {
      variables: { id: +id },
    },
  );

  const [updateCompany, { loading }] = useMutation(
    updateCompanyMutation({
      images: {},
      contact: {
        contactAddresses: { country: {} },
      },
    }),
  );

  const abortController = useRef();

  const { hasPermission } = useUser();
  const hasUpdateCompany = hasPermission("UPDATE_COMPANY");

  const updateCompanyValidation = Yup.object({
    name: Yup.string().required("Please enter a company name"),
    contactId: Yup.number()
      .required("Please select a contact.")
      .typeError("Please select a contact."),
    code: Yup.string().max(5, "Code cannot be longer than 5 characters"),
    invoiceCode: Yup.string().max(
      5,
      "Invoice code cannot be longer than 5 characters",
    ),
    creditCode: Yup.string().max(
      3,
      "Credit note code cannot be longer than 3 characters",
    ),
    invoiceDue: Yup.string().max(
      3,
      "Invoice due date cannot be longer than 3 characters",
    ),
    cds: Yup.string().max(3, "CDS cannot be longer than 3 characters"),
  });

  const formik = useFormik({
    initialValues: company ? company : [],
    validationSchema: hasUpdateCompany
      ? updateCompanyValidation
      : Yup.object({}),
    enableReinitialize: true,
    onSubmit: values => {
      updateCompanyValue(values);
    },
  });

  const updateCompanyValue = values => {
    const changedFields = getChangedFields(values, company);

    const filteredValues = Object.keys(changedFields).reduce((acc, key) => {
      if (!["contact"].includes(key)) {
        acc[key] = changedFields[key];
      }
      return acc;
    }, {});

    if (Object.keys(filteredValues).length > 0) {
      const controller = new AbortController();
      abortController.current = controller;

      updateCompany({
        variables: {
          input: {
            id: +id,
            contactId: values.contactId,
            ...filteredValues,
          },
        },
        context: {
          fetchOptions: {
            signal: controller.signal,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (formik?.values && abortController?.current) {
      abortController.current.abort();
    }
  }, [formik?.values]);

  const bodyProps = {
    ...props,
    company,
    formik,
    loading,
    updateCompany,
    updateCompanyValue,
  };

  return (
    <div className="relative flex min-h-full">
      <Portal id="sidebar">
        <NavBar
          navigation={routes?.filter?.(item => item?.icon)}
          open={props?.open}
        />
      </Portal>
      <div className="m-8 mr-[80px] flex flex-1 overflow-x-auto">
        {company && <Body {...bodyProps} />}
      </div>
    </div>
  );
};

export default Wizard;
