import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import ExportActiveReport from "../../../components/Modals/ExportActiveReport";
import { Breadcrumb, Button, MenuButton, Modal } from "../../../components/ui";
import { FETCH_CONSIGNMENT as fetchConsignment } from "../../../graphql/query/Consignment";
import { EXTEND_CONSIGNMENT as extendConsignmentMutation } from "../../../graphql/mutation/Consignment";

import routes from "../pages/Wizard/routes";
import ConsignmentSearch from "./ConsignmentSearch";
import NewConsignment from "./NewConsignment";
import { consignmentFilterStore } from "./useConsignmentFilters";
import AdhocReportBody from "./Actions/AdhocReportBody";
import exportModule from "./Actions/ExportModule";
import CreateJob from "./Actions/CreateJob";
import { useMemo } from "react";
import CreateShipment from "./Actions/CreateShipment";
import CreateInvoice from "./Actions/CreateInvoice";
import CopyDescription from "./Actions/CopyDescription";
import useUser from "../../../hooks/useUser";

const PrimaryNav = props => {
  const { formik } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const search = consignmentFilterStore.get();
  const { hasPermission } = useUser();

  const [extendConsignment, { loading, error, reset }] = useMutation(
    extendConsignmentMutation(),
  );
  const pathnames = location?.pathname.split("/");
  const consignmentId = pathnames?.length > 2 ? pathnames[2] : null;

  const consignmentObj = {
    channel: true,
    company: true,
    consigner: true,
    consignmentWorks: true,
    contact: true,
    currency: true,
    exhibition: true,
    registrar: false,
    salesUser: true,
    status: true,
    type: true,
  };
  const { data: { consignment } = {} } = useQuery(
    fetchConsignment(consignmentObj),
    { skip: !consignmentId, variables: { id: +consignmentId } },
  );

  const path = pathnames[pathnames?.length - 1];
  const route = routes?.find(item => item?.href?.includes(path));
  const selected = Object.keys(formik?.values?.selected?.ids || {});
  const ids = selected?.map(key => {
    const obj = formik?.values?.selected?.ids?.[key];
    return obj?.item?.id || obj?.id;
  });
  const select = formik?.values?.selected?.select;
  const disabled = ids?.length === 0;

  // prepare steps for breadcrumbs

  const steps = [
    { label: "All Consignments", onClick: () => navigate("/consignment") },
    ...(consignment
      ? [
          {
            label: consignment?.id,
            onClick: () => navigate(`${consignment.id}/form`),
          },
          {
            label: route?.name,
            onClick: () => navigate(`${consignment.id}/${route?.href}`),
          },
        ]
      : []),
  ];

  const breadcrumbProps = {
    currentStepIndex: steps?.length - 1,
    onChange: index => steps[index]?.onClick(),
    steps: steps?.map(item => item?.label),
  };

  const createExtensionModalProps = {
    title: error ? "Error" : "Create Extension?",
    scale: "sm",
    description: error
      ? error?.message
      : `Are you sure you wish to create an extension for Consignment: ${consignment?.id}?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleCreate = () => {
        extendConsignment({
          variables: {
            input: { id: consignment?.id },
          },
          update: (cache, { data }) => {
            const { extendConsignment: { consignment, success } = {} } =
              data || {};
            if (success) {
              console.log("here **");
              closeModal();
              reset();
              navigate(`${consignment?.id}/form`);
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={error ? "Close" : "Cancel"}
            action={error ? "primary" : "default"}
            onClick={() => {
              closeModal?.();
              reset();
            }}
          />
          {!error && (
            <Button
              label={loading ? "Creating" : "Create"}
              disabled={loading}
              onClick={handleCreate}
            />
          )}
        </div>
      );
    },
  };

  const defaultActionProps = {
    closeOnBackdrop: false,
    hideCloseButton: true,
    ids,
    onClose: () => {
      formik?.resetForm();
    },
    scale: "md",
    search,
    select,
  };

  const enableCreateJob = useMemo(
    () => consignment && consignment?.noOfConsignmentWorks > 0,
    [consignment],
  );

  const actionMenuProps = {
    label: "Actions",
    options: [
      {
        label: "New Consignment",
        disabled: !hasPermission("CREATE_CONSIGNMENT"),
        modalProps: {
          body: NewConsignment,
          closeOnBackdrop: false,
          hideCloseButton: true,
          scale: "md",
        },
      },
      {
        disabled,
        label: "Create an Adhoc Report",
        modalProps: {
          body: AdhocReportBody,
          ...defaultActionProps,
          exportModule,
        },
      },
      {
        disabled: !consignment?.id && disabled,
        label: "Export Consignment Report",
        modalProps: {
          ...defaultActionProps,
          body: ExportActiveReport,
          obj: consignmentObj,
          type: "Consignment",
          ids: consignment?.id ? [consignment?.id] : ids,
        },
      },
      ...(consignment?.id
        ? [
            {
              label: "Copy Description",
              modalProps: {
                title: "Copy description?",
                scale: "sm",
                description:
                  "Do you wish to copy the description from the consignment?",
                closeOnBackdrop: true,
                consignmentId: consignment?.id,
                body: CopyDescription,
              },
            },
            {
              label: "Create Extension ",
              modalProps: createExtensionModalProps,
              disabled: !hasPermission("UPDATE_CONSIGNMENT"),
            },
            {
              label: "Create Job",
              title: enableCreateJob
                ? "Create job"
                : "Works are required to be able to create job",
              disabled:
                !hasPermission("UPDATE_CONSIGNMENT") || !enableCreateJob,
              modalProps: {
                body: CreateJob,
                closeOnBackdrop: false,
                hideCloseButton: true,
                scale: "md",
                consignmentId: consignment?.id,
              },
            },
            {
              label: "Create Shipment",
              title: enableCreateJob
                ? "Create shipment"
                : "Works are required to be able to create shipment",
              disabled:
                !hasPermission("UPDATE_CONSIGNMENT") || !enableCreateJob,
              modalProps: {
                body: CreateShipment,
                closeOnBackdrop: false,
                hideCloseButton: true,
                scale: "md",
                consignmentId: consignment?.id,
              },
            },
            {
              label: "Create Invoice",
              title: enableCreateJob
                ? "Create invoice"
                : "Works are required to be able to create invoice",
              disabled:
                !hasPermission("UPDATE_CONSIGNMENT") || !enableCreateJob,
              modalProps: {
                body: CreateInvoice,
                closeOnBackdrop: false,
                hideCloseButton: true,
                scale: "md",
                consignmentId: consignment?.id,
              },
            },
          ]
        : []),
    ],
  };

  const searchModalProps = {
    body: ConsignmentSearch,
    closeOnBackdrop: true,
    scale: "fullscreen",
  };

  return (
    <div className="flex items-center justify-between border-b px-8 py-5">
      <Breadcrumb {...breadcrumbProps} />
      <div className="flex justify-end gap-4">
        <Modal {...searchModalProps}>
          <Button label="Consignment Search" action="black" />
        </Modal>
        <MenuButton {...actionMenuProps} />
      </div>
    </div>
  );
};

export default PrimaryNav;
