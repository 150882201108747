import { useMutation } from "@apollo/client";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { Button, IconButton, Modal } from "../../../../../../../components/ui";

import {
  CONFIRM_SHIPMENT_ITEMS as confirmShipmentItemsMutation,
  REMOVE_SHIPMENT_ITEM as removeShipmentItemMutation,
} from "../../../../../../../graphql/mutation/Shipment";

const Actions = props => {
  const { id, values } = props || {};

  const [
    removeShipmentWarehouseItem,
    {
      data: {
        removeShipmentWarehouseItem: { error: removeShipmentItemError } = {},
      } = {},
      loading: removeShipmentItemLoading,
      reset: resetRemove,
    },
  ] = useMutation(removeShipmentItemMutation(), {
    refetchQueries: ["FetchWarehouseMoveV2"],
  });

  const handleRemove = closeModal => {
    removeShipmentWarehouseItem({
      variables: {
        id: values?.id,
      },
      update: (cache, { data }) => {
        const { removeShipmentWarehouseItem: { success } = {} } = data || {};
        if (success) {
          closeModal?.();
        }
      },
    });
  };

  const [
    confirmShipmentItems,
    {
      data: {
        confirmShipmentItems: { error: confirmShipmentItemsError } = {},
      } = {},
      loading: confirmShipmentItemsLoading,
      reset,
    },
  ] = useMutation(confirmShipmentItemsMutation(), {
    refetchQueries: ["FetchWarehouseMoveV2"],
  });

  const deleteModalProps = {
    title: removeShipmentItemError ? "Error" : "Are you sure?",
    scale: "sm",
    description: removeShipmentItemError
      ? removeShipmentItemError
      : `Would you like to remove this item?`,
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={removeShipmentItemError ? "Close" : "Cancel"}
            action={removeShipmentItemError ? "primary" : "default"}
            onClick={() => {
              resetRemove();
              closeModal?.();
            }}
          />
          {!removeShipmentItemError && (
            <Button
              label={removeShipmentItemLoading ? "Removing" : "Remove"}
              disabled={removeShipmentItemLoading}
              onClick={() => handleRemove(closeModal)}
            />
          )}
        </div>
      );
    },
  };

  const confirmModalProps = {
    title: confirmShipmentItemsError ? "Error" : "Confirm Shipment Item",
    scale: "sm",
    description: confirmShipmentItemsError
      ? confirmShipmentItemsError
      : "Are you sure you wish to confirm this selected shipment items? This cannot be undone.",
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleConfirm = () => {
        confirmShipmentItems({
          variables: {
            input: {
              id: values?.shipmentId,
              itemId: values?.itemId,
            },
          },
          update: (cache, { data }) => {
            const { confirmShipmentItems: { success } = {} } = data || {};
            if (success) {
              closeModal();
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={confirmShipmentItemsError ? "Close" : "Cancel"}
            action={confirmShipmentItemsError ? "primary" : "default"}
            onClick={() => {
              reset();
              closeModal?.();
            }}
          />
          {!confirmShipmentItemsError && (
            <Button
              label={confirmShipmentItemsLoading ? "Confirming" : "Confirm"}
              disabled={confirmShipmentItemsLoading}
              onClick={handleConfirm}
            />
          )}
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <>
        <div className="user-list-actions flex text-right text-sm font-medium">
          {id && (
            <div className="grid grid-cols-2 gap-4">
              {values?.site ? (
                <Modal {...deleteModalProps}>
                  <IconButton variant="clean" title="Remove Item">
                    <XMarkIcon
                      className={`h-4 w-4 cursor-pointer text-gray-400`}
                    />
                  </IconButton>
                </Modal>
              ) : (
                <>
                  <Modal {...confirmModalProps}>
                    <IconButton variant="clean" title="Confirm Item">
                      <CheckIcon
                        className={`h-4 w-4 cursor-pointer text-gray-400`}
                      />
                    </IconButton>
                  </Modal>
                  <Modal {...deleteModalProps}>
                    <IconButton variant="clean" title="Remove Item">
                      <XMarkIcon
                        className={`h-4 w-4 cursor-pointer text-gray-400`}
                      />
                    </IconButton>
                  </Modal>
                </>
              )}
            </div>
          )}
        </div>
        {removeShipmentItemError && (
          <Modal {...{ ...deleteModalProps, isOpen: true }} />
        )}
      </>
    );
  } else {
    return false;
  }
};

export default Actions;
