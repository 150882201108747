import classNames from "classnames";

const Checkbox = props => {
  const {
    options = [],
    error,
    formik,
    onChange,
    singleSelection,
    value,
    singleCheckbox,
    onBlur,
    disabled,
    className,
    submitOnChangeFunc,
  } = props;
  const changeValues = (e, value) => {
    const event = { ...e };
    if (singleCheckbox) {
      formik &&
        formik.setFieldValue(props.name, !event.target.checked ? 0 : value);
    } else {
      onChange?.(event);
    }
  };

  return (
    <fieldset className={`space-y-5 ${className}`}>
      {options?.map((item, key) => {
        const isChecked = `${item.value}` === `${value}`;
        const checkboxClasses = classNames(
          "focus:ring-0 h-4 w-4 text-gray-800 border-gray-800 border-2 rounded",
          { "opacity-50 cursor-not-allowed": disabled },
          { "bg-gray-300": disabled },
        );
        return (
          <div className="relative flex items-start" key={key}>
            <div className="flex h-5 items-center">
              <input
                id={props.name}
                value={item.value}
                checked={isChecked}
                aria-describedby="comments-description"
                name={props.name}
                type={singleSelection ? "radio" : "checkbox"}
                className={checkboxClasses}
                onChange={async e => {
                  changeValues(e, item.value);
                  await submitOnChangeFunc();
                }}
                onBlur={onBlur}
                disabled={disabled}
              />
            </div>
            {item?.label && (
              <div className="ml-6 text-sm">
                <label
                  htmlFor={props.name}
                  className="text-xs font-bold text-gray-700"
                >
                  {item?.label}
                </label>
              </div>
            )}
          </div>
        );
      })}
      {error && <small className="text-sm text-red-600">{error}</small>}
    </fieldset>
  );
};

export default Checkbox;
