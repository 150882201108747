import { PencilIcon } from "@heroicons/react/24/outline";
import { IconButton, Modal } from "../../../../../../../components/ui";
import UpdateCustomsAccount from "./UpdateCustomsAccount";

const Actions = props => {
  const { values } = props || {};
  const { id } = values?.item || values || {};

  const modalProps = {
    body: UpdateCustomsAccount,
    closeOnBackdrop: false,
    hideCloseButton: true,
    scale: "md",
    ...props,
  };

  if (typeof id == "string") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid">
            <Modal {...modalProps}>
              <IconButton variant="clean" title="Edit Customs Account">
                <PencilIcon
                  className={`h-4 w-4 cursor-pointer text-gray-400`}
                />
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
