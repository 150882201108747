import companyFrag from "./Company";
import conditionFrag from "./Condition";
import editionFrag from "./Edition";
import Status from "./Status";
import valueFrag from "./Value";

const Instance = ({
  arrApplicable = false,
  company = false,
  condition = false,
  description = false,
  edition = true,
  isBond = false,
  isGuaranteed = false,
  isOnLoan = false,
  isSecondaryMarket = false,
  status = false,
  unpackedWeight = false,
  value = false,
  location = false,
  volume = false,
  typeId = volume?.typeId || 1,
  weight = false,
} = {}) => `
  id
  ${arrApplicable ? "arrApplicable" : ""}
  artistRef
  artsy
  channelEcommerce
  channelPresentation
  certificate
  certificateSent
  ${
    company
      ? `company {
      ${companyFrag(company)}
    }`
      : ""
  }
  ${
    condition
      ? `condition {
      ${conditionFrag({ status: true })}
    }`
      : ""
  }
  createdAt
  ${description ? "description" : ""}
  ${
    edition
      ? `edition {
      ${editionFrag(edition)}
    }`
      : ""
  }
  editionNumber
  fabricated
  fabricatedDate
  forSale
  framed
  ipad
  bondType
  ${isBond ? "isBond" : ""}
  ${isGuaranteed ? "isGuaranteed" : ""}
  ${isOnLoan ? "isOnLoan" : ""}
  ${isSecondaryMarket ? "isSecondaryMarket" : ""}
  locationHistory
  name
  notes
  parentOnline
  ref
  reference
  returns
  returnsDirector
  ${
    status
      ? `status {
      ${Status}
    }`
      : ""
  }
  statusId
  salesStrategy
  statusHistory
  ${unpackedWeight ? "unpackedWeight" : ""}
  updatedAt
  ${
    value
      ? `value {
      ${valueFrag(value)}
    }`
      : ""
  }
  ${weight ? `weight` : ""}
  ${volume ? `volume(typeId: ${typeId})` : ""}
  ${location ? `location` : ""}
`;

export default Instance;
