import useUser from "../../../../../../hooks/useUser";
import { getInput } from "../../../../../../components/ui";
import SectionHeader from "../../components/SectionHeader";
import { Link } from "react-router-dom";

const ID = ({ disabled, formik, submitOnBlur = false }) => {
  const inputs = [
    {
      label: "ID",
      name: "id",
      type: "text",
      disabled: true,
      submitOnBlur,
    },
    {
      label: "Company Name",
      name: "name",
      type: "text",
      submitOnBlur,
      disabled,
    },
    {
      label: "Contact",
      name: "contactId",
      type: "contactSearch",
      submitOnBlur,
      disabled,
    },
  ];

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      disabled,
      formik,
      ...item,
    };

    return (
      <div
        key={index}
        className={`w-full ${index === 0 ? "md:col-span-2" : "md:col-span-5"}`}
      >
        {getInput(inputProps)}
      </div>
    );
  });

  return (
    <div className="grid w-full gap-4 md:grid-cols-12">{renderInputs}</div>
  );
};

const ContactAddress = ({
  disabled,
  formik,
  submitOnBlur = false,
  company,
}) => {
  const inputs = [
    {
      label: "VAT Number",
      name: "vatno",
      type: "text",
      submitOnBlur,
      disabled,
    },
    {
      label: "Default Photography Credit",
      name: "photoCredit",
      type: "text",
      submitOnBlur,
      disabled,
    },
    {
      label: "Minimum Bond Value",
      name: "bondMinValue",
      type: "number-currency",
      submitOnBlur,
      disabled,
    },
    {
      label: "Active",
      name: "active",
      type: "toggle",
      designType: "checkbox",
      className: "mt-8",
      submitOnBlur,
      disabled,
    },
  ];

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      disabled,
      formik,
      ...item,
    };

    return (
      <div key={index} className="w-full">
        {getInput(inputProps)}
      </div>
    );
  });

  const primaryAddress = company?.contact?.contactAddresses?.filter(
    item => item?.use_default === -1,
  )[0];

  return (
    <div className="grid w-full gap-8 md:grid-cols-12">
      <div className="grid rounded border border-black p-4 md:col-span-6 md:grid-cols-2 lg:col-span-4">
        <div className="">
          <SectionHeader title="Primary Address" />
          <div>{primaryAddress?.address ?? ""}</div>
          <div>{primaryAddress?.address2 ?? ""}</div>
          <div>{primaryAddress?.address3 ?? ""}</div>
          <div>{primaryAddress?.town ?? ""}</div>
          <div>{primaryAddress?.county ?? ""}</div>
          <div>{primaryAddress?.country?.name ?? ""}</div>
          <div>{primaryAddress?.postcode ?? ""}</div>
        </div>

        <Link
          className={`capitalise mb-auto mt-4 rounded border border-black bg-white px-4 py-2 text-center text-base font-medium text-black shadow-sm hover:bg-black hover:text-white focus:outline-none sm:w-auto sm:text-sm md:mt-0 lg:text-xs ${disabled ? "pointer-events-none" : ""}`}
          to={{ pathname: `/contacts/${company?.contactId}/billing-delivery` }}
        >
          Edit
        </Link>
      </div>

      <div className="md:col-span-6 lg:col-span-6 lg:col-start-7">
        <div className="grid gap-4 md:grid-cols-2">{renderInputs}</div>
      </div>
    </div>
  );
};

const CompanyInvoiceDetails = ({ disabled, formik, submitOnBlur = false }) => {
  const inputs = [
    {
      label: "Code",
      name: "code",
      type: "text",
      submitOnBlur,
      disabled,
    },
    {
      label: "Credit Note Code",
      name: "creditCode",
      type: "text",
      submitOnBlur,
      disabled,
    },
    {
      label: "Invoice Code",
      name: "invoiceCode",
      type: "text",
      submitOnBlur,
      disabled,
    },
    {
      label: "Default Invoice Due Date",
      name: "invoiceDue",
      type: "text",
      submitOnBlur,
      disabled,
    },
  ];

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      disabled,
      formik,
      ...item,
    };

    return (
      <div key={index} className="w-full">
        {getInput(inputProps)}
      </div>
    );
  });

  return (
    <div className="grid w-full items-center gap-8 md:grid-cols-4">
      {renderInputs}
    </div>
  );
};

export const Notes = ({ disabled, formik, submitOnBlur = false }) => {
  const inputs = [
    {
      label: "Notes",
      name: "notes",
      type: "textarea",
      submitOnBlur,
      disabled,
    },
  ];

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      disabled,
      formik,
      ...item,
    };

    return (
      <div key={index} className="w-full">
        {getInput(inputProps)}
      </div>
    );
  });

  return <div className="grid w-full items-center gap-8">{renderInputs}</div>;
};

export const Registrar = ({ disabled, formik, submitOnBlur = false }) => {
  const inputs = [
    {
      label: "Shipping Email",
      name: "shippingEmail",
      type: "textarea",
      submitOnBlur,
      disabled,
    },
    {
      label: "CDS Number",
      name: "cds",
      type: "text",
      submitOnBlur,
      disabled,
    },
    {
      label: "HMRC Office Address",
      name: "hmrcAddress",
      type: "textarea",
      submitOnBlur,
      disabled,
    },
  ];

  const renderInputs = inputs?.map((item, index) => {
    const inputProps = {
      disabled,
      formik,
      ...item,
    };

    return (
      <div key={index} className="w-full">
        {getInput(inputProps)}
      </div>
    );
  });

  return <div className="grid w-full items-center gap-8">{renderInputs}</div>;
};

export const CompanyDetails = props => {
  const { formik, company } = props;

  const { hasPermission } = useUser();
  const disabled = !hasPermission("UPDATE_COMPANY");

  return (
    <div className="mb-20 w-full">
      <div className="">
        <ID disabled={disabled} formik={formik} submitOnBlur={true} />

        <div className="mt-10 border-t border-black py-10">
          <ContactAddress
            company={company}
            disabled={disabled}
            formik={formik}
            submitOnBlur={true}
          />
        </div>

        <div className="border-t border-black py-10">
          <CompanyInvoiceDetails
            company={company}
            disabled={disabled}
            formik={formik}
            submitOnBlur={true}
          />
        </div>

        <div className="border-t border-black py-10">
          <SectionHeader title="Notes" />
          <Notes
            company={company}
            disabled={disabled}
            formik={formik}
            submitOnBlur={true}
          />
        </div>

        <div className="border-t border-black py-10">
          <SectionHeader title="Registrar" />
          <Registrar
            company={company}
            disabled={disabled}
            formik={formik}
            submitOnBlur={true}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
