import SectionHeader from "../../components/SectionHeader";
import { FETCH_GUARANTEE_ACCOUNTS_V2 as fetchGuaranteeAccounts } from "../../../../../../graphql/query/GuaranteeAccount";
import { Table } from "../../../../../../components/Functional";
import Actions from "./components/Actions";
import { AddCustomsAccountModal } from "./components/AddCustomsAccount";

const CustomsAccounts = props => {
  const { company } = props;

  const tableProps = {
    headers: [
      {
        label: "Code",
        name: "number",
        sortKey: "id",
        type: "label",
      },
      {
        label: "Description",
        name: "description",
        type: "label",
      },
      {
        label: "Limit",
        name: "accountLimit",
        type: "label",
      },
      {
        label: "CDS",
        name: "cds",
        type: "label",
      },
      {
        label: "Expired",
        name: "expired",
        type: "input",
        inputType: "toggle",
        disabled: true,
        designType: "twoStateCheckbox",
      },
      {
        label: "",
        type: "custom",
        component: Actions,
        companyId: company?.id,
      },
    ],
    dataKey: "guaranteeAccountsV2",
    FETCH_QUERY: fetchGuaranteeAccounts({ contact: {} }),
    variables: {
      _company: {
        contactId: {
          value: [+company?.contactId],
          operator: "in",
        },
      },
      limit: 50,
      offset: 0,
    },
    variant: "dynamicHeight",
    className: "sm:px-0 lg:px-0",
  };

  const AddGuaranteeAccount = [
    {
      component: () => {
        return <AddCustomsAccountModal company={company} />;
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Customs Accounts" actions={AddGuaranteeAccount} />
      <Table {...tableProps} />
    </div>
  );
};

export default CustomsAccounts;
