import SectionHeader from "../../components/SectionHeader";
import { Table } from "../../../../../../components/Functional";
import { FETCH_EXCHANGE_RATES as fetchExchangeRates } from "../../../../../../graphql/query/ExchangeRate";
import { AddExchangeRatesModal } from "./components/AddExchangeRates";

const DateComponent = props => {
  const { updatedAt } = props;
  const date = new Date(updatedAt);

  return (
    <>
      {date.toLocaleDateString()} {date.toLocaleTimeString()}
    </>
  );
};

const ExchangeRates = props => {
  const { company } = props;

  const tableProps = {
    headers: [
      {
        label: "Currency",
        name: "currency.description",
        sortKey: "id",
        type: "label",
      },
      {
        label: "Exchange Rate",
        name: "exchange",
        type: "label",
      },
      {
        label: "Time Stamp",
        name: "updatedAt",
        type: "custom",
        component: props => (
          <DateComponent updatedAt={props.values.updatedAt} />
        ),
      },
    ],
    dataKey: "exchangeRates",
    FETCH_QUERY: fetchExchangeRates({}),
    variables: {
      companyId: company?.id,
      limit: 50,
      offset: 0,
    },
    variant: "dynamicHeight",
    className: "sm:px-0 lg:px-0",
  };

  const AddExchangeRates = [
    {
      component: () => {
        return <AddExchangeRatesModal company={company} />;
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Exchange Rates" actions={AddExchangeRates} />
      <div className="mt-4 flex items-center gap-4">
        <span className="block text-sm font-bold">Base Currency:</span>
        <span className="inline-block rounded border border-black p-1 px-2 text-sm text-gray-500">
          {company?.currency?.description}
        </span>
      </div>
      <Table {...tableProps} />
    </div>
  );
};

export default ExchangeRates;
