import { useQuery } from "@apollo/client";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getChangedFields, useDebounce } from "../../../../util";
import Table from "../../../Table";
import { Button, IconButton, Modal, Pagination, SearchBar } from "../..";
import {
  FETCH_CONTACT as fetchContact,
  FETCH_CONTACTS as fetchContacts,
} from "../../../../graphql/query/Contact";

import { FETCH_WAREHOUSE_SITES } from "../../../../graphql/query/WarehouseSite";
import { GridInputs } from "../../../Functional";

const LIMIT = 5;

const TableCheck = props => {
  const { values, selectedValue, onChange } = props;
  return (
    <div className="relative flex w-[40px] items-start" key={values.id}>
      <div className="flex h-5 items-center">
        <input
          id={values.id}
          value={values.id}
          checked={selectedValue?.id === values.id}
          aria-describedby="comments-description"
          name="id"
          type="checkbox"
          className="h-4 w-4 rounded border-2 border-gray-800 text-gray-800 focus:ring-0"
          onChange={() => onChange(values)}
        />
      </div>
    </div>
  );
};

const ContactSearchInput = props => {
  const {
    disabled,
    error,
    fetchType = "contact",
    label,
    loading,
    onChange: setValue,
    redirect = false,
    value,
    showType = false,
    showCompany = false,
  } = props;
  const initialValues = { fetchType, search: "", limit: LIMIT };

  const { data: { warehouseSites = [] } = {} } = useQuery(
    FETCH_WAREHOUSE_SITES,
  );

  if (fetchType == "shipper") {
    initialValues.typeId = 2;
  } else if (fetchType == "artAdvisor") {
    initialValues.typeId = 32;
  }

  const [filters, setFilters] = useState(initialValues);

  const debouncedFilters = useDebounce(filters, 300);
  const skip = Object.keys(debouncedFilters).length === 0;
  const {
    data: { contacts: { edges = [], count } = {} } = {},
    loading: fetching,
  } = useQuery(fetchContacts({ contactCompany: true, type: showType }), {
    skip,
    variables: { input: { ...debouncedFilters } },
  });
  const { data: { contact = {} } = {} } = useQuery(fetchContact(), {
    skip: !value,
    variables: { id: value },
  });
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    if (!value) {
      setSelectedValue(null);
    } else {
      setSelectedValue({ id: value });
    }
  }, [value]);

  const formikSearch = useFormik({
    initialValues,
  });

  const onChange = values => {
    setFilters(prevState => ({ ...prevState, ...values }));
  };
  const handleSave = () => {
    setValue?.(selectedValue?.id || null);
  };

  const searchBarProps = {
    name: "search",
    placeholder: "Search",
    formik: formikSearch,
    variant: "simple",
  };

  const paginationProps = {
    count,
    limit: LIMIT,
    offset: filters?.offset,
    showSteps: false,
    onChange,
    variant: "simple",
  };

  const inputs = [
    {
      label: "Site",
      name: "siteId",
      options: warehouseSites?.map(item => ({
        label: item?.name,
        value: item?.id,
      })),
      type: "multi-select",
    },
  ];

  const gridInputProps = {
    formik: formikSearch,
    inputs,
  };

  const handleOnClick = value => {
    value?.id === selectedValue?.id
      ? setSelectedValue(null)
      : setSelectedValue(value);
  };

  const tableProps = {
    headers: [
      {
        label: "",
        width: "100px",
        name: "checkbox",
        type: "custom",
        component: props => (
          <TableCheck
            {...props}
            selectedValue={selectedValue}
            onChange={handleOnClick}
          />
        ),
      },
      { label: "Id", name: "id", type: "label" },
      ...(showType
        ? [{ label: "Type", name: "type.description", type: "label" }]
        : []),
      {
        label: "Contact",
        name: "name",
        type: "label",
        labelClass: props => props?.values?.typeId === 3 && "!text-[#149700]",
      },
      {
        label: "Company Name",
        name: "company",
        type: "label",
        labelClass: props => props?.values?.company && "!text-[#0047FF]",
      },
    ],
    limit: LIMIT,
    loading: fetching,
    rows: edges,
    variant: "md",
  };

  useEffect(() => {
    onChange(formikSearch?.values);
    const changedFields = getChangedFields(
      formikSearch?.values,
      formikSearch?.initialValues,
    );
    if (Object.keys(changedFields).length > 0) {
      setFilters(prevState => ({ ...prevState, offset: 0 }));
    }
  }, [formikSearch?.values]);

  const modalProps = {
    scale: "md",
    hideCloseButton: true,
    closeOnBackdrop: true,
    disabled,
    body: ({ closeModal }) => {
      return (
        <div className="flex w-full flex-col p-8">
          <div className="flex flex-row">
            <div className="flex flex-1 flex-col text-2xl font-bold">
              <div>Select {label?.props?.label || label}</div>
            </div>
            <div className="flex flex-col">
              <div>
                <Button
                  action="default"
                  className="mr-8"
                  label="Cancel"
                  onClick={() => {
                    closeModal();
                    setSelectedValue(contact);
                  }}
                />
                <Button
                  action="default"
                  className="mr-8"
                  disabled={!selectedValue}
                  label="Clear Selection"
                  onClick={() => {
                    setSelectedValue(null);
                  }}
                />
                <Button
                  label={loading ? "SAVING" : "SAVE"}
                  disabled={loading || fetching || (!value && !selectedValue)}
                  onClick={() => {
                    closeModal();
                    handleSave();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="pt-12">
            <div className="grid grid-cols-2 items-end gap-10 pb-12">
              <div>
                <SearchBar {...searchBarProps} />
              </div>
              <div className="grid grid-cols-2">
                <GridInputs {...gridInputProps} />
              </div>
            </div>
            <Table {...tableProps} />
            <div>
              <Pagination {...paginationProps} />
            </div>
          </div>
        </div>
      );
    },
  };

  return (
    <div className={`flex flex-1 flex-col`}>
      <div className={`block text-xs font-medium text-black`}>{label}</div>
      <div
        className={`mt-1 flex h-[38px] w-full items-center rounded border border-black focus:border-black focus:ring-0 ${disabled ? "!border-gray-400 !bg-white !text-gray-400" : ""}`}
      >
        <div className={` flex-1 px-3 py-2 text-base `}>
          {redirect ? (
            <Link
              className="underline"
              to={`/contacts/${contact?.id}/details`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {String(contact?.name).trim()
                ? contact?.name
                : contact?.company || contact?.id}
            </Link>
          ) : showCompany && String(contact?.company).trim() ? (
            contact?.company
          ) : String(contact?.name).trim() ? (
            contact?.name
          ) : (
            contact?.company || contact?.id
          )}
        </div>
        <Modal {...modalProps}>
          <IconButton variant="clean" title="Search Contact">
            <MagnifyingGlassIcon
              className={`h-5 w-5 cursor-pointer text-gray-400`}
            />
          </IconButton>
        </Modal>
      </div>
      {error && <small className="text-sm text-red-600">{error}</small>}
    </div>
  );
};

export default ContactSearchInput;
