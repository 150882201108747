import instanceFrag from "./Instance";
import consignmentFrag from "./Consignment";
import entity from "./Entity";

const ConsignmentWork = ({ instance = true, user = false } = {}) => `
  id
  artistNames
  conPrice
  consignment {
    ${consignmentFrag({
      channel: true,
      company: true,
      consigner: true,
      contact: true,
      currency: true,
      exhibition: true,
      registrar: false,
      salesUser: true,
      status: true,
      type: true,
    })}
  }
  consignmentId
  discount
  discountRate
  fabCost
  fabRate
  grossProfit
  instanceId
  ${
    instance
      ? `instance {
      ${instanceFrag(instance)}
    }`
      : ""
  }
  price
  ref
  share
  updatedAt
  ${
    user
      ? `user {
      ${entity}
    }`
      : ""
  }
`;

export default ConsignmentWork;
