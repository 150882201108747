import List from "./pages/List";
import Wizard from "./pages/Wizard";

const routes = [
  {
    path: "*",
    element: List,
    permissions: ["ARTIST_MODULE"],
  },
  {
    path: ":id/*",
    element: Wizard,
    permissions: ["ARTIST_MODULE"],
  },
];

export default routes;
