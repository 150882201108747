export const CreateEditFormInputs = props => {
  const { currencies, disabled, disabledTerms } = props;

  return {
    className: "grid md:grid-cols-12 gap-8",
    inputs: [
      {
        className: "md:col-span-4 gap-4",
        inputs: [
          { label: "Bank Name *", name: "bank", type: "text", disabled },
          { label: "Address", name: "address", type: "text", disabled },
          { label: "Town", name: "town", type: "text", disabled },
          { label: "County", name: "county", type: "text", disabled },
          { label: "Postcode", name: "postcode", type: "text", disabled },
        ],
      },
      {
        className: "md:col-span-8 grid gap-4 md:grid-cols-2",
        inputs: [
          {
            className: "grid-cols-1 gap-4",
            inputs: [
              { label: "Sort Code", name: "sortCode", type: "text", disabled },
              {
                label: "ABA Routing",
                name: "routingNumber",
                type: "text",
                disabled,
              },
            ],
          },
          {
            className: "grid-cols-1 gap-4",
            inputs: [
              {
                label: "Account Number",
                name: "number",
                type: "text",
                disabled,
              },
              {
                label: "Currency *",
                name: "currencyId",
                type: "multi-select",
                isMulti: false,
                options: currencies?.map(currency => ({
                  label: `${currency.description} (${currency.code})`,
                  value: currency.id,
                })),
                disabled,
              },
            ],
          },
          {
            className: "col-span-2 gap-4",
            inputs: [
              {
                label: "Account Holder *",
                name: "holder",
                type: "text",
                disabled,
              },
              { label: "IBAN", name: "iban", type: "text", disabled },
              { label: "Swift Code", name: "swift", type: "text", disabled },
            ],
          },
        ],
      },
      {
        className: "md:col-span-12 gap-4",
        inputs: [
          {
            label: "Invoice Terms",
            name: "invoiceTerms",
            type: "textarea",
            disabled: disabledTerms,
          },
          {
            label: "Invoice Terms (Back)",
            name: "invoiceTermsBack",
            type: "textarea",
            disabled: disabledTerms,
          },
        ],
      },
    ],
  };
};
