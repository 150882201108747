import * as React from "react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

import { Table } from "../../../../../../components/Functional";
import { FETCH_OFFERS_V2 as fetchOffersV2 } from "../../../../../../graphql/query/Offer";

import SectionHeader from "../../components/SectionHeader";
import { AddOffer } from "./components/AddOffer";
import Actions from "./components/Actions";

const TopDealComponent = props => {
  // show check icon if priority is -1
  return (
    <div className="flex items-center justify-center">
      {props?.values?.priority === -1 && (
        <CheckIcon className="h-5 w-5" aria-hidden="true" />
      )}
    </div>
  );
};

const ArtworkOffers = props => {
  const { artworkId } = props || {};

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "offersV2",
    headers: [
      { label: "Date", name: "date", type: "date" },
      {
        label: "Contact",
        name: "contact.name",
        type: "custom",
        component: ({ values }) => (
          <Link
            className="underline"
            to={`/contacts/${values?.contact?.id}/details`}
          >
            {values?.contact?.name || values?.contact?.id}
          </Link>
        ),
      },
      { label: "Status", name: "status.description", type: "label" },
      { label: "Currency", name: "currency.code", type: "label" },
      { label: "Amount", name: "amount", type: "currency" },
      { label: "TBI Amount", name: "tbiAmount", type: "currency" },
      {
        label: "Top Deal",
        name: "priority",
        type: "custom",
        component: TopDealComponent,
      },
      {
        label: "Hold",
        name: "hold.name",
        type: "custom",
        component: props => {
          return (
            <Link
              className="underline"
              to={`/instance/${props?.values?.hold?.id}/details`}
            >
              {props?.values?.hold?.name}
            </Link>
          );
        },
      },
      { label: "User", name: "user", type: "label" },
      { label: "Date Closed", name: "dateClosed", type: "date" },
      { label: "Notes", name: "notes", type: "label" },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    FETCH_QUERY: fetchOffersV2({
      contact: {},
      currency: {},
      status: {},
      hold: {},
    }),
    variables: {
      _artwork: {
        id: {
          operator: "eq",
          value: artworkId,
        },
      },
    },
    className: "sm:px-0 lg:px-0",
  };

  const offersActions = [
    {
      component: () => <AddOffer artworkId={artworkId} />,
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Offers" actions={offersActions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default ArtworkOffers;
