import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import routes from "../pages/Wizard/routes";

import ExportActiveReport from "../../../components/Modals/ExportActiveReport";
import { Breadcrumb, Button, MenuButton, Modal } from "../../../components/ui";
import { FETCH_WAREHOUSE_ITEM as fetchWarehouseItem } from "../../../graphql/query/WarehouseItem";
import { DELETE_WAREHOUSE_ITEM as deleteWarehouseItemMutation } from "../../../graphql/mutation/WarehouseItem";
import WarehouseSearch from "./WarehouseSearch";
import MoveItem from "./MoveItem";
import useUser from "../../../hooks/useUser";
import NewWarehouse from "./NewWarehouse";
import AdhocReportBody from "./Actions/AdhocReportBody";
import exportModule from "./Actions/ExportModule";
import { warehouseFilterStore } from "./useWarehouseFilter";

const PrimaryNav = props => {
  const { formik } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = location?.pathname.split("/");
  const search = warehouseFilterStore.get();

  const { hasPermission } = useUser();
  // get id from url
  const id = pathnames?.length > 2 ? pathnames[2] : null;
  // fetch warehouse details if id is available
  const warehouseObj = {
    artist: {
      contact: {},
    },
    category: {},
    instance: {
      arrApplicable: {},
      company: {},
      condition: {},
      description: {},
      edition: {},
      isBond: {},
      isGuaranteed: {},
      isOnLoan: {},
      isSecondaryMarket: {},
      status: {},
      unpackedWeight: {},
      value: {},
      location: {},
      volume: {},
      typeId: 1,
      weight: {},
    },
    itemSite: {
      item: {},
      location: {},
      shipment: {},
      site: {},
    },
    warehouseMoves: {
      item: {},
      location: {},
      shipment: {},
      site: {},
    },
    weightType: {},
  };
  const { data: { warehouseItem } = {} } = useQuery(
    fetchWarehouseItem(warehouseObj),
    {
      skip: !id,
      variables: { id: +id },
    },
  );

  // find current submodule route
  const path = pathnames[pathnames?.length - 1];
  const route = routes?.find(item => item?.href?.includes(path));
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams.get("status") || "all";
  const selected = Object.keys(formik?.values?.selected?.ids || {});
  const listId = +(queryParams.get("listId") || status === "selectListWorking"
    ? queryParams.get("workingListId")
    : null);
  const ids = selected?.map(key => {
    const obj = formik?.values?.selected?.ids?.[key];
    return obj?.item?.id || obj?.id;
  });
  const select = formik?.values?.selected?.select;
  const disabled = ids?.length === 0;

  const [
    deleteWarehouseItem,
    {
      data: {
        deleteWarehouseItem: { error: deleteWarehouseItemError } = {},
      } = {},
      loading: deleteWarehouseItemLoading,
      reset,
    },
  ] = useMutation(deleteWarehouseItemMutation());

  const statusMap = {
    all: "All Warehouse",
    searched: "Searched Warehouse",
    selectListWorking: "Select List (Working)",
  };
  const label = statusMap[status] || "All Warehouse";
  // prepare steps for breadcrumbs

  const steps = [
    { label, onClick: () => navigate(`/warehouse?status=${status}`) },
    ...(warehouseItem
      ? [
          {
            label: warehouseItem?.id,
            onClick: () =>
              navigate(`${warehouseItem?.id}/details?status=${status}`),
          },
          {
            label: route?.name,
            onClick: () => navigate(`${warehouseItem?.id}/${route?.href}`),
          },
        ]
      : []),
  ];

  const breadcrumbProps = {
    currentStepIndex: steps?.length - 1,
    onChange: index => steps[index]?.onClick(),
    steps: steps?.map(item => item?.label),
  };

  const deleteModalProps = {
    title: deleteWarehouseItemError ? "Error" : "Delete Warehouse Item",
    scale: "sm",
    description: deleteWarehouseItemError
      ? deleteWarehouseItemError
      : "Are you sure you wish to delete this warehouse item? This cannot be undone.",
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteWarehouseItem({
          variables: {
            input: { id: warehouseItem?.id },
          },
          update: (cache, { data }) => {
            const { deleteWarehouseItem: { success } = {} } = data || {};
            if (success) {
              closeModal();
              cache.evict({
                id: cache.identify({
                  __typename: "WarehouseItem",
                  id,
                }),
              });
              navigate("/warehouse");
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={deleteWarehouseItemError ? "Close" : "Cancel"}
            action={deleteWarehouseItemError ? "primary" : "default"}
            onClick={() => {
              reset();
              closeModal?.();
            }}
          />
          {!deleteWarehouseItemError && (
            <Button
              label={deleteWarehouseItemLoading ? "Deleting" : "Delete"}
              disabled={deleteWarehouseItemLoading}
              onClick={handleDelete}
            />
          )}
        </div>
      );
    },
  };

  const defaultActionProps = {
    closeOnBackdrop: false,
    hideCloseButton: true,
    ids,
    listId,
    onClose: () => {
      formik?.resetForm();
    },
    scale: "md",
    search,
    select,
  };

  const actionMenuProps = {
    label: "Actions",
    options: [
      {
        label: "New Warehouse",
        modalProps: {
          body: NewWarehouse,
          closeOnBackdrop: false,
          hideCloseButton: true,
          scale: "md",
        },
      },
      {
        disabled,
        label: "Create an Adhoc Report",
        modalProps: {
          body: AdhocReportBody,
          ...defaultActionProps,
          exportModule,
        },
      },
      {
        disabled: !warehouseItem?.id && disabled,
        label: "Export Warehouse Report",
        modalProps: {
          ...defaultActionProps,
          body: ExportActiveReport,
          obj: warehouseObj,
          type: "WarehouseItem",
          ids: warehouseItem?.id ? [warehouseItem?.id] : ids,
        },
      },
      ...(warehouseItem?.id
        ? [
            {
              label: "Move Item",
              modalProps: {
                body: MoveItem,
                closeOnBackdrop: false,
                warehouseItem,
                hideCloseButton: true,
                scale: "md",
              },
            },
            {
              disabled: !hasPermission("DELETE_WAREHOUSE_ITEM"),
              label: "Delete warehouse item",
              modalProps: deleteModalProps,
            },
          ]
        : []),
    ],
  };

  const modalProps = {
    body: WarehouseSearch,
    closeOnBackdrop: true,
    scale: "fullscreen",
  };

  return (
    <div className="flex items-center justify-between border-b px-8 py-5">
      <Breadcrumb {...breadcrumbProps} />
      <div className="flex justify-end gap-4">
        <Modal {...modalProps}>
          <Button label="Warehouse Search" action="black" />
        </Modal>
        <MenuButton {...actionMenuProps} />
      </div>
    </div>
  );
};

export default PrimaryNav;
