import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FETCH_CONTACT_ATTRIBUTE_TYPES } from "../../../../graphql/query/ContactAttributeType";
import { CREATE_CONTACT_ATTRIBUTE } from "../../../../graphql/mutation/ContactAttribute";
import { Button, getInput } from "../../../../components/ui";

const AddContactAttribute = props => {
  const { closeModal, artist } = props;
  const { data: { contactAttributeTypes = [] } = {} } = useQuery(
    FETCH_CONTACT_ATTRIBUTE_TYPES,
  );
  const [createContactAttribute, { loading }] = useMutation(
    CREATE_CONTACT_ATTRIBUTE(),
    {
      refetchQueries: ["FetchContactAttributes"],
    },
  );

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({
      description: Yup.string().required("Please enter a description"),
      typeId: Yup.number().required("Please select a type"),
    }),
    onSubmit: async values => {
      createContactAttribute({
        variables: {
          input: {
            ...values,
            contactId: artist?.contact?.id,
          },
        },
      }).then(() => {
        closeModal();
      });
    },
  });

  const inputs = [
    {
      label: "Type",
      name: "typeId",
      type: "multi-select",
      options: [
        ...contactAttributeTypes.map(item => ({
          label: item?.description,
          value: item?.id,
        })),
      ],
    },
    {
      label: "Description",
      name: "description",
      type: "text",
    },
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Add Contact Attribute</div>
        </div>
        <div className="flex gap-4">
          <Button action="default" label="Cancel" onClick={closeModal} />
          <Button
            label="Create"
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="w-50 grid grid-cols-2 gap-4">
        {inputs
          ?.filter(item => !!item)
          .map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return <div key={index}>{getInput(inputProps)}</div>;
          })}
      </div>
    </div>
  );
};

export default AddContactAttribute;
