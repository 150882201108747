import { useQuery, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { Button, Modal } from "../../../../../../../components/ui";
import { GridInputs } from "../../../../../../../components/Functional";
import { FETCH_CURRENCIES } from "../../../../../../../graphql/query/Currency";
import { FETCH_EXCHANGE_RATES as fetchExchangeRates } from "../../../../../../../graphql/query/ExchangeRate";
import { CREATE_EXCHANGE_RATE } from "../../../../../../../graphql/mutation/ExchangeRate";

const AddExchangeRatesBody = props => {
  const { closeModal, company } = props || {};

  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);

  const [createExchangeRate, { loading }] = useMutation(CREATE_EXCHANGE_RATE, {
    refetchQueries: [fetchExchangeRates()],
  });

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit: async values => {
      const rateValues = Object.entries(values).map(val => {
        return {
          currencyId: Number(val[0]),
          exchange: val[1],
        };
      });
      createExchangeRate({
        variables: {
          input: {
            companyId: company.id,
            rates: rateValues,
          },
        },
      }).then(resp => {
        const {
          data: { createExchangeRate: { success } = {} },
        } = resp || {};
        if (success) {
          formik.resetForm();
          closeModal();
        }
      });
    },
  });

  const currencyInputs = currencies
    .filter(curr => curr.id !== 13 && curr.description !== null)
    .map(curr => {
      return {
        label: curr.description,
        name: curr.id,
        type: "number-currency",
      };
    });

  const inputs = {
    inputs: [
      {
        className: "gap-8 grid grid-cols-6",
        inputs: currencyInputs,
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Add Exchange Rates</div>
        </div>
        <div className="flex gap-4 ">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik.resetForm();
            }}
          />
          <Button
            label={"Create"}
            loading={loading}
            disabled={loading}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="mt-4 grid">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

const AddExchangeRatesModal = props => {
  const modalProps = {
    body: AddExchangeRatesBody,
    closeOnBackdrop: false,
    hideCloseButton: true,
    scale: "md",
    ...props,
  };

  return (
    <Modal {...modalProps}>
      <Button label="Add Exchange Rates" />
    </Modal>
  );
};

export { AddExchangeRatesModal, AddExchangeRatesBody };
