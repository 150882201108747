import { Breadcrumb, Button, MenuButton, Modal } from "../../../components/ui";
import { useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { FETCH_ARTIST as fetchArtist } from "../../../graphql/query/Artist";
import ArtistSearch from "./ArtistSearch";
import { artistFilterStore } from "./useArtistFilters";
import NewArtist from "./Actions/NewArtist";
import routes from "../pages/Wizard/routes";
import AddContactAttribute from "./Actions/AddContactAttribute";
import useUser from "../../../hooks/useUser";

const PrimaryNav = props => {
  const { formik } = props;
  const search = artistFilterStore.get();
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = location?.pathname.split("/");
  const id = pathnames?.length > 2 ? pathnames[2] : null;
  const { hasPermission } = useUser();

  const artistObj = {
    contact: {},
  };
  const { data: { artist } = {} } = useQuery(fetchArtist(artistObj), {
    skip: !id,
    variables: { id: +id },
  });
  const path = pathnames[pathnames?.length - 1];
  const route = routes?.find(item => item?.href?.includes(path));
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams.get("status") || "all";
  const listId = +(
    queryParams.get("listId") ||
    (status === "selectListWorking" ? queryParams.get("workingListId") : null)
  );
  const selected = Object.keys(formik?.values?.selected?.ids || {});
  const ids = selected?.map(key => {
    const obj = formik?.values?.selected?.ids?.[key];
    return obj?.item?.id || obj?.id;
  });
  const select = formik?.values?.selected?.select;

  const statusMap = {
    all: "All Artists",
    searched: "Searched Artists",
    selectListWorking: "Select List (Working)",
  };
  const label = statusMap[status] || "All Artists";
  const steps = [
    { label, onClick: () => navigate("/artist") },
    ...(artist
      ? [
          {
            label: artist?.name,
            onClick: () => navigate(`${artist?.id}/details`),
          },
          {
            label: route?.name,
            onClick: () => navigate(`${artist?.id}/${route?.href}`),
          },
        ]
      : []),
  ];

  const breadcrumbProps = {
    currentStepIndex: steps?.length - 1,
    onChange: index => steps[index]?.onClick(),
    steps: steps?.map(item => item?.label),
  };

  const searchModalProps = {
    body: ArtistSearch,
    closeOnBackdrop: true,
    scale: "md",
  };

  const defaultActionProps = {
    closeOnBackdrop: false,
    hideCloseButton: true,
    ids: ids.length ? ids : [+id],
    listId,
    onClose: () => {
      formik?.resetForm();
    },
    scale: "md",
    search,
    select,
  };

  const actionMenuProps = {
    label: "Actions",
    options: [
      {
        label: "New Artist",
        disabled: !hasPermission("CREATE_ARTIST"),
        modalProps: {
          body: NewArtist,
          disabled: !hasPermission("CREATE_ARTIST"),
          ...defaultActionProps,
        },
      },
      {
        disabled: !id,
        label: "Add Contact Attribute",
        modalProps: {
          body: AddContactAttribute,
          ...defaultActionProps,
          hideCloseButton: false,
          closeOnBackdrop: true,
          artist,
        },
      },
    ],
  };

  return (
    <div className="flex items-center justify-between border-b px-8 py-5">
      <Breadcrumb {...breadcrumbProps} />
      <div className="flex justify-end gap-4">
        <Modal {...searchModalProps}>
          <Button label="Artist Search" action="black" />
        </Modal>
        <MenuButton {...actionMenuProps} />
      </div>
    </div>
  );
};

export default PrimaryNav;
