export const CreateEditFormInputs = {
  className: "grid md:grid-cols-12 gap-8",
  inputs: [
    {
      className: "md:col-span-10 gap-4 grid md:grid-cols-4",
      inputs: [
        {
          label: "Code",
          name: "number",
          type: "text",
        },
        {
          label: "Description",
          name: "description",
          type: "text",
        },
        {
          label: "Limit",
          name: "accountLimit",
          type: "number-currency",
        },
        {
          label: "CDS",
          name: "cds",
          type: "text",
        },
      ],
    },
    {
      className: "md:col-span-2 gap-4",
      inputs: [
        {
          className: "",
          inputs: [
            {
              label: "Expired",
              name: "expired",
              type: "toggle",
              designType: "checkbox",
              className: "mt-8",
            },
          ],
        },
      ],
    },
  ],
};
