import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Table } from "../../../../../../components/Functional";
import { UPDATE_WAREHOUSE_MOVE as updateWarehouseMoveMutation } from "../../../../../../graphql/mutation/WarehouseMove";
import { FETCH_WAREHOUSE_MOVE_V2 as fetchWarehouseMoveV2 } from "../../../../../../graphql/query/WarehouseMove";
import { FETCH_WAREHOUSE_LOCATIONS } from "../../../../../../graphql/query/WarehouseLocation";
import { FETCH_ARTWORK_TARIFFS } from "../../../../../../graphql/query/ArtworkTariff";
import { getInput } from "../../../../../../components/ui";

import AddItem from "./components/AddItem";
import Actions from "./components/Actions";
import SectionHeader from "../../../../../Warehouse/pages/Wizard/components/SectionHeader";
import AddWork from "./components/AddWork";

const EditionNumber = ({ label }) => {
  const { edition, editionNumber } = label || {};
  if (editionNumber && edition?.number && edition?.number > 1) {
    return (
      <div className="flex flex-col">
        <span className="text-base font-medium leading-5 text-black">{`${editionNumber}/${edition?.number}`}</span>
      </div>
    );
  }
};

const Items = props => {
  const { shipment } = props || {};

  const { data: { artworkTariffs = [] } = {} } = useQuery(
    FETCH_ARTWORK_TARIFFS,
  );
  const { data: { warehouseLocations = [] } = {} } = useQuery(
    FETCH_WAREHOUSE_LOCATIONS,
  );

  const tableProps = {
    className: "sm:px-0 lg:px-0",
    dataKey: "warehouseMoveV2",
    FETCH_QUERY: fetchWarehouseMoveV2({
      item: { category: {}, instance: { edition: { artwork: {} } } },
      site: true,
    }),
    headers: [
      {
        label: "Storage #",
        name: "item.id",
        sortKey: "id",
        type: "custom",
        component: ({ values }) => (
          <Link
            className={`underline ${!values?.confirmed ? "!text-[#FF7400]" : values?.ti === -1 ? "!text-[#8400FF]" : ""}`}
            to={`/warehouse/${values?.item?.id}/details`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {values?.item?.id}
          </Link>
        ),
      },
      { label: "Category", name: "item.category.description", type: "label" },
      { label: "Description", name: "item.description", type: "label" },
      {
        label: "Reference",
        name: "item.instance.name",
        type: "custom",
        component: ({ values }) => (
          <Link
            className="underline"
            to={`/instance/${values?.item?.instance?.id}/details`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {values?.item?.instance?.name}
          </Link>
        ),
      },
      {
        label: "Title",
        name: "item.instance.edition.artwork.title",
        type: "label",
      },
      {
        label: "Edition",
        name: "item.instance",
        type: "custom",
        component: EditionNumber,
      },
      { label: "Year", name: "item.instance.edition.year", type: "label" },
      {
        inputType: "number-currency",
        label: "Value",
        name: "value",
        type: "input",
        allowEmpty: true,
      },
      {
        designType: "threeStateCheckbox",
        inputType: "toggle",
        label: "CC",
        name: "ti",
        type: "input",
      },
      { inputType: "text", label: "Ref", name: "bondref", type: "input" },
      {
        inputType: "number-currency",
        label: "TA Days",
        name: "taDays",
        type: "input",
        inputClassName: "w-[65px]",
        allowDecimal: false,
        allowEmpty: true,
      },
      {
        label: "Location",
        name: "locationId",
        type: "custom",
        inputClassName: "w-[150px]",
        component: props => {
          const { values } = props || {};

          const locations =
            warehouseLocations
              ?.filter(item => item?.siteId === values?.siteId)
              ?.map(item => ({
                label: item?.description,
                value: item.id,
              })) || [];

          const input = getInput({
            ...props,
            label: "",
            type: "multi-select",
            options: locations,
          });

          return input;
        },
      },
      {
        type: "input",
        label: "Goods Code",
        name: "goodsCode",
        inputType: "multi-select",
        options: artworkTariffs?.map(tariff => ({
          label: tariff?.code,
          value: tariff?.code,
        })),
      },
      { label: "User", name: "user", type: "label" },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    UPDATE_MUTATION: updateWarehouseMoveMutation({
      item: { category: {}, instance: { edition: { artwork: {} } } },
      site: true,
    }),
    variables: {
      _shipment: {
        id: {
          operator: "eq",
          value: shipment?.id,
        },
      },
    },
    variant: "dynamicHeight",
  };

  const actions = [
    {
      component: () => {
        return (
          <>
            <AddItem shipment={shipment} />
            <AddWork shipment={shipment} />
          </>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader
        title={`Items - Shipment ${shipment?.id}`}
        actions={actions}
      />
      <div className="w-96 px-8 pb-2">
        <p className="mb-2">Unconfirmed Items</p>
        <p className="bg-[#2222220D] p-3 text-red-500">
          {shipment?.unconfirmedItemCount}
        </p>
      </div>
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default Items;
