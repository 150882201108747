import { gql } from "@apollo/client";

const CREATE_BANK_ACCOUNT = gql`
  mutation CreateBankAccount($input: CreateBankAccountInput!) {
    createBankAccount(input: $input) {
      success
      error
      bankAccount {
        id
        address
        bank
        county
        companyId
        currency {
          id
          code
          description
          name
        }
        details
        holder
        invoiceTerms
        number
        postcode
        sortCode
        town
      }
    }
  }
`;

const UPDATE_BANK_ACCOUNT = gql`
  mutation UpdateBankAccount($input: UpdateBankAccountInput!) {
    updateBankAccount(input: $input) {
      success
      error
      bankAccount {
        id
        address
        bank
        county
        companyId
        currency {
          id
          code
          description
          name
        }
        details
        holder
        invoiceTerms
        number
        postcode
        sortCode
        town
      }
    }
  }
`;

const DELETE_BANK_ACCOUNT = gql`
  mutation DeleteBankAccount($input: DeleteBankAccountInput!) {
    deleteBankAccount(input: $input) {
      success
      error
    }
  }
`;

export { CREATE_BANK_ACCOUNT, UPDATE_BANK_ACCOUNT, DELETE_BANK_ACCOUNT };
