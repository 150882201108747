import {
  ClipboardIcon,
  LinkIcon,
  ArrowRightStartOnRectangleIcon,
  ShieldCheckIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";

import Details from "./pages/Details";
import ConsignmentTerms from "./pages/ConsignmentTerms";
import BankAccounts from "./pages/BankAccounts";
import CustomsAccounts from "./pages/CustomsAccounts";
import HeaderFooter from "./pages/HeaderFooter";
import ExchangeRates from "./pages/ExchangeRates";

const routes = [
  {
    element: Details,
    href: "details",
    icon: ClipboardIcon,
    name: "Company Details",
  },
  {
    element: ConsignmentTerms,
    href: "consignment-terms",
    icon: ArrowRightStartOnRectangleIcon,
    name: "Consignment Terms",
  },
  {
    element: BankAccounts,
    href: "bank-accounts",
    icon: LinkIcon,
    name: "Bank Accounts",
  },
  {
    element: CustomsAccounts,
    href: "customs-accounts",
    icon: ShieldCheckIcon,
    name: "Customs Accounts",
  },
  {
    element: HeaderFooter,
    href: "header-footer",
    icon: EnvelopeIcon,
    name: "Header & Footer Images",
  },
  {
    element: ExchangeRates,
    href: "exchange-rates",
    icon: CurrencyDollarIcon,
    name: "Exchange Rates",
  },
];

export default routes;
