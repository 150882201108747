import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  CheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

import Actions from "./components/Actions";
import AddRecipient from "./components/AddRecipient";
import CSVUpload from "./components/CSVUpload";

import Table from "../../../../../components/Table";
import {
  Button,
  FilterBar,
  getInput,
  IconButton,
  Pagination,
  Tag,
} from "../../../../../components/ui";
import { DELETE_EMAIL_CONTACTS as deleteEmailContactsMutation } from "../../../../../graphql/mutation/Email";
import { FETCH_EMAIL_CONTACTS } from "../../../../../graphql/query/EmailContact";
import { FETCH_ENTITIES } from "../../../../../graphql/query/Entity";
import { FETCH_MAILING_LISTS } from "../../../../../graphql/query/MailingList";
import { useDebounce } from "../../../../../util";

const RecipientSender = props => {
  const limit = 10;
  const { id } = useParams();
  const { email, formik } = props || {};
  const [deleteEmailContact] = useMutation(deleteEmailContactsMutation(), {
    refetchQueries: [FETCH_EMAIL_CONTACTS],
  });
  const [fetchEmailContacts, { data: { emailContacts } = {} }] = useLazyQuery(
    FETCH_EMAIL_CONTACTS,
    { fetchPolicy: "network-only" },
  );
  const { data: { entities } = {} } = useQuery(FETCH_ENTITIES);
  const { data: { mailingLists: { edges = [] } = {} } = {} } =
    useQuery(FETCH_MAILING_LISTS);

  const filterFormik = useFormik({
    initialValues: {
      limit,
      offset: 0,
    },
  });

  const debouncedFilters = useDebounce(filterFormik?.values, 300);
  const { sender, senderName = "" } = formik?.values?.meta || {};
  const preview = `${senderName ? `${senderName} ` : ""}(${sender}@whitecube.com)`;

  useEffect(() => {
    formik?.getFieldHelpers("meta.senderPreview")?.setValue(preview);
  }, [sender, senderName]);

  const inputs = [
    {
      name: "meta.sender",
      label: "Sender",
      type: "multi-select",
      options:
        entities?.edges?.map(item => {
          return { label: item.name, value: item.name };
        }) || [],
    },
    { name: "meta.senderName", label: "Sender Name", type: "text" },
    {
      disabled: true,
      name: "meta.senderPreview",
      label: "Sender Preview",
      type: "text",
      value: preview,
    },
  ];

  const recipientInputs = [
    {
      name: "meta.recipientId",
      label: "Mailing List",
      type: "multi-select",
      options: edges?.map(item => ({
        label: item?.description,
        value: item?.id,
      })),
    },
  ];

  const onChange = values => {
    filterFormik?.setValues({ ...filterFormik?.values, ...values });
  };

  useEffect(() => {
    console.log("changed", email?.meta?.recipientId);
    fetchEmailContacts({
      variables: {
        input: {
          emailId: +id,
          ...debouncedFilters,
        },
      },
    });
  }, [email?.meta?.recipientId, debouncedFilters]);

  const renderInputs = inputs =>
    inputs?.map((item, index) => {
      const inputProps = {
        formik,
        ...item,
      };

      return (
        <div key={index} className="flex flex-1">
          {getInput(inputProps)}
        </div>
      );
    });

  const TagColumn = props => {
    const { values } = props || {};
    const { mailingListId, meta: { body, subject, isUnsubscribed } = {} } =
      values || {};

    return (
      <div className="flex gap-2">
        {!mailingListId && <Tag label={"Added to Mailing"} action="primary" />}
        {(body || subject) && <Tag label={"Edited"} action="primary" />}
        {isUnsubscribed && <Tag label={"Unsubscribed"} action="danger" />}
      </div>
    );
  };

  const ValidationColumn = props => {
    const { values } = props || {};
    const { meta: { body, email, subject, isUnsubscribed } = {} } =
      values || {};

    const errors = {
      body: body !== undefined && body?.length == 0 ? "Body is missing" : "",
      email: !email ? "Email is missing" : "",
      subject:
        subject !== undefined && subject?.length == 0
          ? "Subject is missing"
          : "",
      isUnsubscribed: isUnsubscribed ? "Contact is Unsubscribed" : "",
    };

    const isInvalid = Object.values(errors).filter(item => item).length > 0;
    const error = Object.values(errors)
      .filter(item => item)
      .join(", ");

    return (
      <div className="flex gap-2">
        <IconButton
          title={isInvalid ? error : "Valid"}
          className="border-none hover:bg-transparent"
        >
          {!isInvalid ? (
            <CheckIcon className="h-5 w-5 text-green-600" />
          ) : (
            <ExclamationTriangleIcon className="h-5 w-5 text-red-600" />
          )}
        </IconButton>
      </div>
    );
  };

  const tableProps = {
    headers: [
      { label: "Contact", name: "meta.name", type: "label" },
      { label: "Email Address", name: "meta.email", type: "label" },
      { label: "Salutation", name: "meta.salutation", type: "label" },
      { label: "Validation", type: "custom", component: ValidationColumn },
      { label: "", type: "custom", component: TagColumn },
      { label: "Action", name: "action", type: "custom", component: Actions },
    ],
    rows: emailContacts?.edges,
    limit,
    variant: "default",
  };

  const paginationProps = {
    count: emailContacts?.count,
    limit,
    offset: filterFormik?.values?.offset,
    onChange,
  };

  const addRecipientProps = {
    emailId: +id,
    label: "Contacts",
  };

  const filterBarProps = {
    formik: filterFormik,
    inputs: [
      {
        label: "Show Invalid Only",
        name: "isInvalid",
        type: "toggle",
        view: "partial",
      },
      { label: "Email", name: "email", type: "text", view: "partial" },
      { label: "Name", name: "name", type: "text", view: "partial" },
      { label: "From", name: "from", type: "date", view: "partial" },
      { label: "To", name: "to", type: "date", view: "partial" },
    ],
  };

  const csvUploadProps = {
    id: +id,
    formik,
  };

  return (
    <>
      <div className="mb-4 flex flex-col">
        <div className="mt-5">
          <div className="mb-5 flex">Sender Information</div>
          <div className="grid grid-cols-4 gap-8 gap-x-16">
            {renderInputs(inputs)}
          </div>
        </div>
        <div className="mt-8">
          <div className="flex">Add recipients</div>
          <div className="grid grid-cols-4 gap-x-16">
            <div className="flex items-end gap-8">
              <AddRecipient {...addRecipientProps} />
              <CSVUpload {...csvUploadProps} />
              <Button
                label="Clear"
                action="default"
                onClick={() =>
                  deleteEmailContact({
                    variables: {
                      input: {
                        id: +id,
                      },
                    },
                  })
                }
              />
            </div>
            <div className="flex">{renderInputs(recipientInputs)}</div>
          </div>
        </div>
      </div>
      <div className="mx-auto flex-grow py-16">
        <header>
          <div className="flex flex-grow items-center justify-between">
            <div className="font-beausite-detail text-lg font-medium">
              Recipient List:
            </div>
            <div className="flex flex-grow items-center justify-end">
              <FilterBar {...filterBarProps} />
            </div>
          </div>
        </header>
        <main>
          <div className="flex-col p-4 sm:px-0">
            <Table className="border-2" {...tableProps} />
            <Pagination {...paginationProps} />
          </div>
        </main>
      </div>
    </>
  );
};

export default RecipientSender;
