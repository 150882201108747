import { FETCH_COMPANIES as fetchCompanies } from "../../../../../graphql/query/Company";

const SearchedCompanies = () => {
  const tableProps = {
    dataKey: "companies",
    FETCH_QUERY: fetchCompanies({
      contact: {
        country: {},
      },
    }),
  };

  return tableProps;
};

export default SearchedCompanies;
