import { useMutation } from "@apollo/client";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Button, IconButton, Modal } from "../../../../../../../components/ui";
import UpdateBankAccount from "./UpdateBankAccount";
import { DELETE_BANK_ACCOUNT } from "../../../../../../../graphql/mutation/BankAccount";
import { FETCH_BANK_ACCOUNTS_V2 as fetchBankAccountsV2 } from "../../../../../../../graphql/query/BankAccount";
import useUser from "../../../../../../../hooks/useUser";

const Actions = props => {
  const { values } = props || {};
  const { id } = values?.item || values || {};

  const [deleteBankAccount, { loading }] = useMutation(DELETE_BANK_ACCOUNT, {
    refetchQueries: [fetchBankAccountsV2({ currency: {} })],
  });

  const modalProps = {
    body: UpdateBankAccount,
    closeOnBackdrop: false,
    hideCloseButton: true,
    scale: "md",
    ...props,
  };

  const deleteModalProps = {
    title: "Are you sure?",
    scale: "sm",
    description: `Deleting a bank account cannot be undone`,
    closeOnBackdrop: true,
    disabled: values?.canDelete === false,
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteBankAccount({
          variables: {
            input: { id: +values?.rawFields?.id },
          },
          update: () => {
            closeModal();
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };

  const { hasPermission } = useUser();
  const disabled = !hasPermission("UPDATE_BANK_ACCOUNT");
  const disabledTerms = !hasPermission("UPDATE_BANK_ACCOUNT_TERMS");

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (!disabled || !disabledTerms) && (
          <div className="grid grid-cols-2 gap-4">
            <Modal {...modalProps}>
              <IconButton variant="clean" title="Edit Bank Account">
                <PencilIcon
                  className={`h-4 w-4 cursor-pointer text-gray-400`}
                />
              </IconButton>
            </Modal>
            {!disabled && (
              <Modal {...deleteModalProps}>
                <IconButton variant="clean" title="Delete Bank Account">
                  <TrashIcon
                    title={`${values?.canDelete === false ? "Cannot delete bank account. Bank Account is being used by one or more invoices" : ""}`}
                    className={`h-4 w-4 cursor-pointer text-gray-400 ${values?.canDelete === false ? "opacity-50 hover:cursor-not-allowed" : "opacity-100"}`}
                  />
                </IconButton>
              </Modal>
            )}
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
