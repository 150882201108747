import { ClipboardIcon, HomeIcon } from "@heroicons/react/24/outline";
import Details from "./pages/Details";

const routes = [
  {
    href: "/artist",
    icon: HomeIcon,
    name: "All Artists",
  },
  {
    element: Details,
    href: "details",
    icon: ClipboardIcon,
    name: "Artist Details",
  },
];

export default routes;
