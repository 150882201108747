import { FETCH_ARTISTS_V2 as fetchArtistsV2 } from "../../../../../graphql/query/Artist";
import { artistFilterStore } from "../../../components/useArtistFilters";

const SearchedArtists = props => {
  const tableProps = {
    dataKey: "artistsV2",
    FETCH_QUERY: fetchArtistsV2({}),
    variables: {
      ...artistFilterStore.get(),
    },
  };

  return tableProps;
};

export default SearchedArtists;
