import { gql } from "@apollo/client";
import { BankAccount as bankAccount } from "../fragments";

const FETCH_BANK_ACCOUNTS = props => gql`
  query FetchBankAccounts($input : BankAccountsFilterInput) {
    bankAccounts(input: $input) {
      ${bankAccount(props)}
    }
  }
`;

const FETCH_BANK_ACCOUNTS_V2 = props => gql`
  query FetchBankAccountsV2($input : BankAccountsFilterInput) {
    bankAccountsV2(input: $input) {
      count
      edges {
        ${bankAccount(props)}
      }
      sqlQuery
    }
  }
`;

export { FETCH_BANK_ACCOUNTS, FETCH_BANK_ACCOUNTS_V2 };
