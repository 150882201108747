import Currency from "./Currency";

const BankAccount = ({ currency = false } = {}) => `
  id
  address
  bank
  canDelete
  county
  county
  currencyId
  ${
    currency
      ? `currency {
      ${Currency}
    }`
      : ""
  }
  details
  holder
  iban
  invoiceTerms
  invoiceTermsBack
  number
  postcode
  routingNumber
  sortCode
  swift
  town
  updatedAt
`;

export default BankAccount;
