import { gql } from "@apollo/client";
import { Artist as artist } from "../fragments";

const CREATE_ARTIST = props => gql`
	mutation CreateArtist($input: CreateArtistInput!) {
		createArtist(input: $input) {
			success
			error
			artist {
				${artist(props)}
			}
		}
	}
`;

const UPDATE_ARTIST = props => gql`
	mutation UpdateArtist($input: UpdateArtistInput!) {
		updateArtist(input: $input) {
			success
			error
			artist {
				${artist(props)}
			}
		}
	}
`;

export { CREATE_ARTIST, UPDATE_ARTIST };
