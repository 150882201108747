import SectionHeader from "../../components/SectionHeader";
import Table from "../../../../../../components/Table";
import { useFormik } from "formik";
import Actions from "./components/Actions";
import useUser from "../../../../../../hooks/useUser";

export const HeaderFooter = props => {
  const { company } = props;
  const { hasPermission } = useUser();
  const disabled = !hasPermission("UPDATE_COMPANY_LOGO");

  const formik = useFormik({
    initialValues: company
      ? {
          header: { url: company?.images?.header },
          footer: { url: company?.images?.footer },
          logo: { url: company?.images?.logo },
        }
      : {},
    enableReinitialize: true,
  });

  const rows = [
    { type: "Logo", image: company?.images?.logo, key: "logo" },
    { type: "Header", image: company?.images?.header, key: "header" },
    { type: "Footer", image: company?.images?.footer, key: "footer" },
  ];

  const tableProps = {
    formik: formik,
    headers: [
      {
        label: "Type",
        type: "label",
        name: "type",
        disabled,
      },
      {
        label: "Image",
        type: "image",
        name: "image",
        disabled,
      },
      {
        label: "Actions",
        type: "custom",
        component: Actions,
        company: company,
        disabled,
      },
    ],
    limit: 10,
    rows: rows,
    variant: "dynamicHeight",
    className: "sm:px-0 lg:px-0",
  };

  return (
    <div className="mb-20 w-full">
      <div className="flex flex-col space-x-20 md:flex-row">
        <div className="w-full">
          <SectionHeader title="Edit Company Header / Footer" />
          <Table {...tableProps} />
        </div>
      </div>
    </div>
  );
};

export default HeaderFooter;
