import { gql } from "@apollo/client";

export const AddressClassificationDefault = "DEFAULT";
export const AddressClassificationBilling = "BILLING";
export const AddressClassificationDelivery = "DELIVERY";
export const AddressClassificationStatement = "STATEMENT";

export const FETCH_CONTACT_ADDRESS = gql`
  query FetchContactAddress(
    $contactId: Int!
    $classification: AddressClassification!
  ) {
    contactAddress(contactId: $contactId, classification: $classification) {
      address
      address2
      address3
      country {
        id
        continent
        digraph
        name
      }
      countryId
      county
      town
      postcode
    }
  }
`;
